import React, {useContext, useDeferredValue} from 'react';
import ClientContext from '../../../context/ClientContext';
import PaginationPerPage from '../../layouts/PaginationPerPage';
import Loading from '../../layouts/Loading';
import Global from '../../../libs/Global';
import Client from './Client';

const Clients = (props) => {
    const {auth} = props
    const {
        total,
        clients,
        isLoading,
        deleteClient,
        onChangePerPage, perPage,
        pageinate, page,
        onSearch, search,
        openForm,
        editClientFnc
        // updateLeader
    } = useContext(ClientContext)

    const clientList = useDeferredValue(clients);

    const handleDelete = (id) => {
        if (window.confirm(Global.deleteMessage())) {
            deleteClient(id);
        }
    }
    const handlerLeader = (id, isLeader) => {
        if (window.confirm(Global.changeLeaderMessage())) {
            // updateLeader(id, isLeader === '1' ? false : true)
        }
    }

    return isLoading ? (
        <Loading className="w-25"/>
    ) : (
        <main>
            <div className="table-head p-1">
                <h3>Client List</h3>
                <form className="float-end" action="#">
                    <input type="text" placeholder={Global.placeholderSearch()}
                           className="sr-input"
                           value={search}
                           onChange={e => onSearch(e)}/>
                    <button type="button" className="btn sr-btn" onClick={onSearch}>
                        <i className="bi bi-search"></i>
                    </button>
                </form>
            </div>

            <PaginationPerPage
                perPage={perPage}
                total={total}
                pageinate={pageinate}
                currentPage={page}
                onChangePerPage={onChangePerPage}/>

            <table className="table table-striped data-table table-hover table-body p-2">
                <thead className="table-success">
                <tr>
                    <th scope="col" className="hidden-mobile">#</th>
                    <th scope="col" className="hidden-mobile">ID</th>
                    <th scope="col" className="hidden-mobile">Name</th>
                    <th scope="col" className="hidden-mobile">Email</th>
                    <th scope="col" className="hidden-mobile">Phone</th>
                    <th scope="col" className="hidden-mobile">Address</th>
                    <th scope="col" className="hidden-mobile">Content</th>
                    <th scope="col" className="text-end hidden-mobile" colSpan={2}>
                        {/* <a className="download btn btn-primary" href={process.env.REACT_APP_API_ENDPOINT + "/export-client?token=" + auth.authorisation.token} download="ss" target="_blank" rel="noreferrer">
                                <i className="bi bi-download"></i> Excel
                            </a>
                            {" "}
                             */}
                        <button className="btn btn-success" onClick={openForm}><i className="bi bi-plus-lg"></i>
                        </button>
                    </th>
                    <th scope="col" className="show-mobile hidden-tablet">Clients Information</th>
                    <th scope="col" className="text-end show-mobile hidden-tablet">
                        {/* <a className="download btn btn-primary" href={process.env.REACT_APP_API_ENDPOINT + "/export-client?token=" + auth.authorisation.token} download="ss" target="_blank" rel="noreferrer">
                                <i className="bi bi-download"></i> Excel
                            </a>
                            {" "} */}
                        <button className="btn btn-success" onClick={openForm}><i className="bi bi-plus-lg"></i>
                        </button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {clientList && clientList.length ? (
                    clientList.map((client, index) => (
                        <tr key={client.id}>
                            <th scope="row" className="hidden-mobile">{index + 1}</th>
                            <td className="hidden-mobile">{client.id}</td>
                            <td className="hidden-mobile">{client.name}</td>
                            <td className="hidden-mobile">{client.email}</td>
                            <td className="hidden-mobile">{client.phone}</td>
                            <td className="hidden-mobile">{client.address}</td>
                            <td className="hidden-mobile">{client.content}</td>
                            <th scope="row" className="show-mobile hidden-tablet">
                                <p><b>#</b>{index + 1}. <b className="text-success">ID: </b>{client.id}</p>
                                <p className="text-primary">
                                    <i className="bi bi-person">{" "}</i>{client.name}</p>
                                <p><b>
                                    <i className="bi bi-envelope">{" "}</i> </b>{client.email}</p>
                                {" "}
                                <p className="btn btn-success">
                                    <i className="bi bi-phone">{" "}</i> {client.phone}</p>
                                {" "}
                                <p className="btn btn-warning">
                                    <i className="bi bi-pin-map">{" "}</i> {client.address}</p>
                                {" "}
                                <p>
                                    <i className="bi bi-book">{" "}</i> {client.content}</p>
                            </th>
                            <td className="text-end">
                                <button type="button" className="btn btn-warning mt-1"
                                        onClick={() => editClientFnc(client)}>
                                    <i className="bi bi-pencil-square"></i>
                                </button>
                                {" "}
                                <div className="show-mobile hidden-tablet"><br/></div>
                                <button type="button" className="btn btn-danger mt-1"
                                        onClick={() => handleDelete(client.id)}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="hidden-mobile" colSpan={8}>There are no clients.</td>
                        <td className="show-mobile hidden-tablet" colSpan={2}>There are no clients.</td>
                    </tr>
                )}
                </tbody>
            </table>
            <Client/>
        </main>
    )
}

export default Clients
