import React, {useEffect, useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import Loading from '../../layouts/Loading';
import ProjectTypeContext from '../../../context/ProjectTypeContext';
import useWindowDimensions from '../../../libs/useWindowDimensions'
import Global from '../../../libs/Global';

const ProjectType = (props) => {
    const {
        isOpen, closeModal,
        projecttypeEdit,
        errorMessage,
        addProjectType,
        updateProjectType
    } = useContext(ProjectTypeContext)
    const [name, setName] = useState('')
    const [status, setStatus] = useState(0)
    const [orderby, setOrderby] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [fullscreen, setFullscreen] = useState('')
    const {modelSize} = useWindowDimensions()

    const submitFormHandler = (projecttype) => {
        if (projecttypeEdit.isEdit) {
            updateProjectType(projecttypeEdit.projecttype.id, projecttype);
        } else {
            addProjectType(projecttype);
        }
    }

    // Generate JSX code for error message
    const renderErrorMessage = (message, className = "text-danger") => (
        <div className={className}>
            {message}
        </div>
    );

    useEffect(() => {
        if (projecttypeEdit.isEdit) {
            setName(projecttypeEdit.projecttype.name)
            setStatus(Global.isEnableProjectType(projecttypeEdit.projecttype.status) ? 0 : 1)
            setOrderby(projecttypeEdit.projecttype.orderby ? projecttypeEdit.projecttype.orderby : 0)
        } else {
            setName('')
            setStatus(0)
            setOrderby(0)
        }
    }, [projecttypeEdit]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            status: status,
            orderby: orderby
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required"),
            status: Yup.number().required("Required!"),
            orderby: Yup.number().max(127).required("Required!"),
        }),
        onSubmit: values => {
            const param = JSON.stringify(values, null, 2);
            submitFormHandler(JSON.parse(param))
            setIsSubmitted(true);
        }
    });

    const projecttypeForm = () => (
        <Modal dialogClassName={modelSize + " modal-dialog-centered modal-dialog-scrollable " + fullscreen}
               show={isOpen} onHide={closeModal} animation={true}>
            <Modal.Header closeButton className="manager-head bg-light">
                <h3 className="card-title w-100">
                    {projecttypeEdit.isEdit ? "Update Project Type" : "Add New Project Type"}
                </h3>
                <button className="float-end me-2 btn" onClick={() => {
                    setFullscreen(fullscreen === 'modal-fullscreen' ? '' : 'modal-fullscreen')
                }}>
                    <i className={fullscreen === 'modal-fullscreen' ? "bi-arrows-angle-contract" : "bi bi-arrows-fullscreen"}></i>
                </button>
            </Modal.Header>
            <Modal.Body style={{height: "90%"}}>
                <div className="d-flex justify-content-center h-100 w-100">
                    <div className="container-fluid card register mt-3">
                        <div className="card-body">
                            {isSubmitted ? (
                                <Loading className="w-25"/>
                            ) : ''}
                            {renderErrorMessage(errorMessage)}
                            {/* {renderErrorMessage(successMessages, "text-success")} */}
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Type" name="name"
                                           value={formik.values.name}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.name && formik.touched.name &&
                                        renderErrorMessage(formik.errors.name)
                                    }
                                    <label className="control-label" htmlFor="name">
                                        <i className="bi bi-list-check"></i> Project Type</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="status" name="status"
                                            onChange={formik.handleChange}
                                            value={formik.values.status}>
                                        <option value={0}
                                                label={Global.projectTypeEnable()}>{Global.projectTypeEnable()}</option>
                                        <option value={1}
                                                label={Global.projectTypeDisable()}>{Global.projectTypeDisable()}</option>
                                    </select>
                                    {formik.errors.status && formik.touched.status &&
                                        renderErrorMessage(formik.errors.status)}
                                    <label className="control-label" htmlFor="status">
                                        <i className="bi bi-radioactive"></i> Status</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Order" name="orderby"
                                           value={formik.values.orderby}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.orderby && formik.touched.orderby &&
                                        renderErrorMessage(formik.errors.orderby)
                                    }
                                    <label className="control-label" htmlFor="orderby">
                                        <i className="bi bi-sort-numeric-down-alt"></i> Order</label>
                                </div>
                                <div className="form-group text-end mt-2">
                                    <button type="submit" className="btn btn-success">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
    );

    return projecttypeForm()

}

export default ProjectType
