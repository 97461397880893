import React, {useContext} from 'react'
import AuthContext from '../../../context/AuthContext'
import {SettingProvider} from '../../../context/SettingProvider'
import Global from '../../../libs/Global'
import Settings from './Settings'

const SettingManager = () => {
    const {auth} = useContext(AuthContext);
    return auth && auth.user && Global.isSuperAdmin(auth.user.type) ? (
        <div className="container mb-5">
            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">
                    <SettingProvider>
                        <Settings/>
                    </SettingProvider>
                </div>
            </div>
        </div>
    ) : Global.reLogin()
}

export default SettingManager
