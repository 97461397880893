import React, {useEffect, useState} from 'react'
import addNotification from "react-push-notification";
import logoIcon from "../../assets/images/logo512.png"
import ProjectService from '../../libs/service/ProjectService';

const Notification = () => {
    const [pushNotifications, setPushNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadPage, setLoadPage] = useState(false);

    const getAllNotification = () => {
        // setIsLoading(false);
        setIsLoading(false)
        const res = ProjectService.pushNotifications({})
        res.then((response) => {
            setPushNotifications(response.data)
            setIsLoading(true)
        }).catch((error) => {
            console.log(error)
            setIsLoading(true)
        })
    }

    useEffect(() => {
        //live check mail
        const handleCheckProject = ({detail}) => {
            setLoadPage(false)
            getAllNotification()
        }
        window.addEventListener(`loadProjectNotification-1`, handleCheckProject)

        getAllNotification()

        return () => {
            getAllNotification()
        }
    }, [])

    const callPushNotifications = (item) => {
        addNotification({
            title: item.title,
            message: item.message,
            duration: 300000,
            icon: logoIcon,
            native: true,
            onClick: () => window.location = process.env.REACT_APP_PUBLIC_URL + item.redirect + ''
        })
    }

    return !loadPage ? (
        <div className="crm-push-notifications">
            {/* <button onClick={handlerNotification}>Test Click Push Notification</button> */}
            {pushNotifications.length &&
                pushNotifications.map((item, index) => (
                    <div key={item.id}>
                        {callPushNotifications(item)}
                        {pushNotifications.length === index + 1 ? setLoadPage(true) : ''}
                    </div>
                ))}

        </div>
    ) : <></>
}

export default Notification
