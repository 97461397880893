import React, {useContext} from 'react'
import AuthContext from '../../../context/AuthContext';
import Global from '../../../libs/Global';
import Notification from '../../layouts/Notification';

const LoadNotifications = () => {

    const {auth} = useContext(AuthContext);

    return auth && auth.user && Global.isSuperAdmin(auth.user.type)
        ? <Notification/>
        : <></>
}

export default LoadNotifications
