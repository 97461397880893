import React, {useContext} from 'react'
import AuthContext from '../../../context/AuthContext'
import {UserProvider} from '../../../context/UserProvider'
import Global from '../../../libs/Global'
import Users from './Users'

const UserManager = () => {
    const {auth} = useContext(AuthContext);
    return auth && auth.user && auth.user.type && Global.isSuperAdmin(auth.user.type) ? (
        <div className="container mb-5">
            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">
                    <UserProvider>
                        <Users auth={auth}/>
                    </UserProvider>
                </div>
            </div>
        </div>
    ) : Global.reLogin()
}

export default UserManager
