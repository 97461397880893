import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Login from './components/screens/auth/Login';
//for Management
import UserManager from './components/screens/system/UserManager';
import ProjectManager from './components/screens/system/ProjectManager';
import ProjectLogManager from './components/screens/system/ProjectLogManager';
import ProjectTypeManager from './components/screens/system/ProjectTypeManager';
import CronManager from './components/screens/system/CronManager';
import SettingManager from './components/screens/system/SettingManager';
import ClientManager from './components/screens/system/ClientManager';
//for CRON JOB
import LoadNotifications from './components/screens/system/LoadNotifications';

function App() {
    return (
        <>
            <LoadNotifications/>
            <Header />
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/user" element={<UserManager />} />
                <Route path="/project" element={<ProjectManager />} />
                <Route path="/project-log" element={<ProjectLogManager />} />
                <Route path="/project-type" element={<ProjectTypeManager />} />
                <Route path="/cron" element={<CronManager />} />
                <Route path="/holiday" element={<SettingManager />} />
                <Route path="/client" element={<ClientManager />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
