import {memo} from 'react'

const TotalHours = ({total}) => {
    const handelCalculate = (e) => {
        e.preventDefault()
        // console.log('test')
    }

    return (
        <button className="btn btn-warning mb-3" onClick={handelCalculate}>
            <i className="bi bi-calendar-range">{" "} Click to calculate range total hours: <b>{total}</b></i>
        </button>
    )
}

export default memo(TotalHours);
