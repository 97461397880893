import clientInstance from "./clientInstance"

const ClientService = {
    getClientList: async (params) => {
        return await clientInstance.get(
            '/client',
            {
                params: params,
            }
        )
    },
    getClient: async (id) => {
        return await clientInstance.get(
            '/client/' + id
        )
    },
    getClients: async () => {
        return await clientInstance.get(
            '/client/create'
        )
    },
    createClient: async (params) => {
        return await clientInstance.post(
            '/client',
            params
        )
    },
    updateClient: async (id, params) => {
        return await clientInstance.put(
            '/client/' + id,
            params
        )
    },
    deleteClient: async (id) => {
        return await clientInstance.delete(
            '/client/' + id
        )
    }
}

export default ClientService
