import React, {useState, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import ProjectTypeContext from "./ProjectTypeContext";
import ProjectTypeService from "../libs/service/ProjectTypeService";
import moment from "moment-timezone";
import {allTimezones} from "react-timezone-select";

export const ProjectTypeProvider = ({children}) => {
    const navigate = useNavigate()
    // const [authMail, setAuthMail] = useState([]);
    const [projecttypes, setProjectTypes] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [projecttypeEdit, setProjectTypeEdit] = useState({projecttype: {}, isEdit: false});
    const [params, setParams] = useState({})
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false)
    const closeModal = () => {
        setIsOpen(false)
        setProjectTypeEdit({projecttype: {}, isEdit: false});
    }
    const openForm = () => {
        setProjectTypeEdit({projecttype: {}, isEdit: false});
        setIsOpen(true)
    }
    const editProjectTypeFnc = (projecttype) => {
        //pass item info to form edit
        setProjectTypeEdit({projecttype, isEdit: true});
        setIsOpen(true)
    };

    /***Paging ACTION***/
    const pageinate = (pageNumber) => {
        pageNumber = pageNumber ? pageNumber : 1
        setPage(pageNumber)
        const newParams = params
        newParams.page = pageNumber
        newParams.perPage = perPage
        newParams.search = search
        setParams(newParams)
    }

    // on change per page
    const onChangePerPage = (e) => {
        const newParams = params
        newParams.perPage = parseInt(e.target.value)
        newParams.page = 1
        setPerPage(parseInt(e.target.value))
    }

    const onSearch = (e) => {
        e.preventDefault()
        const newParams = params
        newParams.search = e.target.value
        newParams.page = 1
        newParams.perPage = perPage

        setSearch(e.target.value)
        setParams(newParams)
    }

    //timezone
    const [tz, setTz] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [datetime, setDatetime] = useState(moment());
    useMemo(() => {
        const tzValue = tz.value ?? tz; //??
        setDatetime(datetime.tz(tzValue));
    }, [tz, datetime]);

    //Load List
    useEffect(() => {
        fetchProjectTypes()

        return () => {
            fetchProjectTypes()
        };
    }, [perPage, page, search])

    //call API
    const fetchProjectTypes = () => {
        const res = ProjectTypeService.getProjectTypeList(params);
        res.then(
            (response) => {
                if (response.status === 401) {
                    localStorage.clear()
                    navigate('/')
                    navigate(0)
                }
                setProjectTypes(response.data.data)
                setTotal(response.data.total)
                setIsLoading(false)
            }
            , (error) => {
                //Unauthenticated.
                if (error.response.status === 401) {
                    localStorage.clear()
                    navigate('/')
                    navigate(0)

                    //call api refresh token.
                    //ProjectTypeService.refreshToken()
                }
                setIsLoading(false)
            }
        )

        return projecttypes;
    };

    const reloadProjectTypes = () => {
        const newParams = params
        newParams.search = search
        newParams.page = page
        newParams.perPage = perPage
        setParams(newParams)
        fetchProjectTypes()
        setErrorMessage('')
    }

    // projecttype form
    const addProjectType = (newProjectType) => {
        setIsLoading(true)
        const res = ProjectTypeService.createProjectType(newProjectType)
        res.then((response) => {
            reloadProjectTypes()
            setIsLoading(false)
            //close popup
            setIsOpen(false)
            setProjectTypeEdit({projecttype: {}, isEdit: false});
        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setIsLoading(false)
        })
    };

    const updateProjectType = (id, projecttype) => {
        setIsLoading(true)
        const res = ProjectTypeService.updateProjectType(id, projecttype)
        res.then((response) => {
            reloadProjectTypes()
            setIsLoading(false)
            //close popup
            setIsOpen(false)
            setProjectTypeEdit({projecttype: {}, isEdit: false});
        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setIsLoading(false)
        })
    };

    const deleteProjectType = (id) => {
        setIsLoading(true)
        const res = ProjectTypeService.deleteProjectType(id)
        res.then(response => {
            // console.log(response)
            setIsLoading(false)
            reloadProjectTypes()
        }).catch(error => {
            // console.log(error)
            setIsLoading(false)
        })
    };

    const changeStatusItem = (id, status) => {
        setIsLoading(true)
        const res = ProjectTypeService.updateProjectType(id, {status: status})
        res.then(response => {
            // console.log(response)
            setIsLoading(false)
            reloadProjectTypes()
            // fetchProjectTypes()
        }).catch(error => {
            // console.log(error)
            setIsLoading(false)
        })
    };

    //ke ra ai thich sai cai nao thi sai
    const shareProvider = {
        total,
        projecttypes,
        isLoading,
        addProjectType,
        updateProjectType,
        deleteProjectType,
        editProjectTypeFnc,
        errorMessage,
        page,
        pageinate,
        perPage,
        onChangePerPage,
        search,
        onSearch,
        fetchProjectTypes,
        isOpen,
        closeModal,
        openForm,
        projecttypeEdit,
        tz,
        setTz,
        datetime,
        allTimezones,
        changeStatusItem
    }
    return (
        <ProjectTypeContext.Provider value={shareProvider}>
            {children}
        </ProjectTypeContext.Provider>
    );
};
