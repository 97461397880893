import React, {useEffect, useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import Loading from '../../layouts/Loading';
import ProjectContext from '../../../context/ProjectContext';
import useWindowDimensions from '../../../libs/useWindowDimensions'
import {DefaultEditor, BtnBold, BtnItalic, Editor, Toolbar} from 'react-simple-wysiwyg';
import Global from '../../../libs/Global';
import UserService from "../../../libs/service/UserService";
import {useNavigate} from 'react-router-dom';

const ProjectLeader = () => {
    const {
        isOpenLeader, closeModalLeader,
        errorMessage,
        btnRef,
        projectAssign,
        saveProjectUsers
    } = useContext(ProjectContext)

    const navigate = useNavigate()

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [content, setContent] = useState('')
    const [projectTitle, setProjectTitle] = useState(0)
    const [projectId, setProjectId] = useState(0)
    const [userId, setUserId] = useState(0)
    const [users, setUsers] = useState([])
    const [projectUserId, setProjectUserId] = useState(0)

    // Customize your editor with css rules
    var editorStyle = {
        overflow: 'auto',
        height: 150,
        maxHeight: 150
    }
    const [fullscreen, setFullscreen] = useState('')
    const {modelSize} = useWindowDimensions()

    //edit form
    const formik = useFormik({
        initialValues: {
            id: projectUserId,
            project_id: projectId,
            user_id: userId,
            content: content
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            user_id: Yup.number()
                .min(1, 'Required')
        }),
        onSubmit: values => {
            if (btnRef.current) {
                btnRef.current.setAttribute("disabled", "disabled")
            }
            setIsSubmitted(true);
            const param = JSON.stringify(values, null, 2);
            submitFormHandler(JSON.parse(param))
        }
    });

    const submitFormHandler = (projectUsers) => {
        //id: exist -> update else create new
        saveProjectUsers(projectUsers)
    }

    const loadLeaders = () => {
        const res = UserService.getUserLeader();
        res.then(response => {
            setUsers(response.data)
        }).catch(error => {
            if (error.response.status === 401) { //Unauthenticated.
                localStorage.clear()
                navigate('/')
                navigate(0)
            } else {
                // console.log(error)
            }
        })
    }

    useEffect(() => {
        if (projectAssign && projectAssign.project && projectAssign.project.id) {
            setProjectId(projectAssign.project.id)
            setProjectTitle(projectAssign.project.title)
            if (projectAssign.isEdit) {
                setProjectUserId(projectAssign.project.projectUserId)
                setUserId(projectAssign.project.userId)
                setContent(projectAssign.project.assignContent)
            } else {
                setProjectUserId(0)
                setUserId(0)
                setContent('')
            }
        }

        loadLeaders()

        return () => {
            loadLeaders()
        }
    }, [projectAssign]);

    const projectLeaderForm = () => (
        <Modal dialogClassName={modelSize + " modal-dialog-centered modal-dialog-scrollable " + fullscreen}
               show={isOpenLeader}
               onHide={closeModalLeader} animation={true}>
            <Modal.Header closeButton className="manager-head bg-light">
                <h3 className="card-title w-100">
                    Assignee Leader
                </h3>
                <button className="float-end me-2 btn"
                        onClick={() => {
                            setFullscreen(fullscreen === 'modal-fullscreen' ? '' : 'modal-fullscreen')
                        }}>
                    <i className={fullscreen === 'modal-fullscreen' ? "bi-arrows-angle-contract" : "bi bi-arrows-fullscreen"}></i>
                </button>
            </Modal.Header>
            <Modal.Body style={{height: "90%"}}>
                <div className="card-body">
                    {isSubmitted ? (
                        <Loading className="w-25"/>
                    ) : ''}

                    {Global.renderErrorMessage(errorMessage)}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control"
                                   value={projectTitle}
                                   readOnly={true}
                            />
                            <label className="control-label" htmlFor="title">
                                <i className="bi bi-clipboard">{" "}</i>Project Name
                            </label>
                        </div>
                        <div className="form-floating mb-3">
                            <select className="form-select" id="user_id"
                                    name="user_id"
                                    onChange={e => {
                                        formik.handleChange(e);
                                        setUserId(e.target.value)
                                    }}
                                    value={formik.values.user_id}>
                                <option value={0} label={"Select Leader"}/>
                                {users.map((item, key) => (
                                    <option key={key} value={item.id} label={item.name}/>
                                ))}
                            </select>
                            {formik.errors.user_id && formik.touched.user_id &&
                                Global.renderErrorMessage(formik.errors.user_id)}

                            <label className="label-control" htmlFor="user_id">
                                <i className="bi bi-person-circle fs-5 link-primary"></i> Select Leader
                            </label>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="content" className="label-control">Content</label>
                            <DefaultEditor
                                id="content"
                                name="content"
                                value={formik.values.content}
                                onChange={formik.handleChange}
                                style={editorStyle}
                            >
                                <Toolbar>
                                    <BtnBold/>
                                    <BtnItalic/>
                                    <Editor/>
                                </Toolbar>
                            </DefaultEditor>
                            {formik.errors.content && formik.touched.content &&
                                Global.renderErrorMessage(formik.errors.content)
                            }
                        </div>

                        <div className="form-group text-end">
                            <button type="submit" className="btn btn-success" ref={btnRef}>
                                Save
                            </button>
                        </div>
                    </form>
                </div>

            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
    )

    return projectLeaderForm()
}

export default ProjectLeader
