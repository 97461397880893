import {useContext} from 'react'
import {useFormik} from 'formik';
import * as Yup from 'yup';
import AuthContext from '../../../context/AuthContext';
import Loading from '../../layouts/Loading';
import Dashboard from '../page/Dashboard';
import Homepage from '../page/Homepage';
import Global from '../../../libs/Global';

const Login = () => {
    const {
        auth,
        handleSubmit,
        isSubmitted,
        errorMessage,
        setErrorMessage,
    } = useContext(AuthContext);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email format")
                .required("Required"),
            password: Yup.string()
                .required("Required"),
        }),
        onSubmit: values => {
            setErrorMessage('')
            const param = JSON.stringify(values, null, 2);
            handleSubmit(JSON.parse(param))
        }
    });

    // Generate JSX code for error message
    const renderErrorMessage = (message) => (
        <div className="text-danger">{message}</div>
    );

    return auth.status === 'success' ?
        Global.isSuperAdmin(auth.user.type)
            ? <Dashboard auth={auth}/>
            : <Homepage auth={auth}/>
        : (
            <div className=" d-flex justify-content-center h-100 w-100 mt-5">
                <div className="card">
                    <h5 className="card-header">Login</h5>
                    <div className="card-body">
                        {isSubmitted ? (


                            <div className="centered text-center w-50">
                                <Loading className="w-50"/>
                            </div>
                        ) : ''}
                        {renderErrorMessage(errorMessage)}
                        <form
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="input-group form-group mb-3">
                                <label className="form-control text-center w-25 text-primary"><i
                                    className="bi bi-person"></i></label>
                                <input type="text" className="form-control w-75 text-primary" placeholder="Email"
                                       name="email"
                                       value={formik.values.email}
                                       onChange={formik.handleChange}
                                />
                                {formik.errors.email && formik.touched.email &&
                                    renderErrorMessage(formik.errors.email)}
                            </div>
                            <div className="input-group form-group mb-3">
                                <label className="form-control text-center w-25 text-primary"><i
                                    className="bi bi-lock"></i></label>
                                <input type="password" className="form-control w-75 text-primary" placeholder="Password"
                                       name="password"
                                       value={formik.values.password}
                                       onChange={formik.handleChange}
                                />
                                {formik.errors.password && formik.touched.password &&
                                    renderErrorMessage(formik.errors.password)}
                            </div>

                            {/* <div className="mb-3">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                        </div>
                    </div> */}

                            <div className="mb-3 text-end">
                                <button type="submit"
                                    // disabled={isSubmitting}
                                        className="btn btn-success mr-1">
                                    {/* {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
                                    <i className="bi bi-box-arrow-in-right"></i> Login
                                </button>
                            </div>
                            {/* <p className="forgot-password text-right">
                        Forgot <a href="#">password?</a>
                    </p> */}
                        </form>
                    </div>
                </div>
            </div>
        )
}

export default Login
