import clientInstance from "./clientInstance"

const LogService = {
    getProjectLogList: async (params) => {
        return await clientInstance.get(
            '/project-log',
            {
                params: params,
            }
        )
    },
    getCronLogList: async (params) => {
        return await clientInstance.get(
            '/cron',
            {
                params: params,
            }
        )
    },
}

export default LogService
