import React from 'react'
import Global from '../../libs/Global'

const Footer = () => {
    return (
        <div className="site-footer mt-5">
            <footer className="site-info">
                <span className="copyright">© 2022 {Global.brandName()}</span>
            </footer>
        </div>
    )
}

export default Footer
