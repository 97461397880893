import {useContext} from 'react';
import PaginationPerPage from '../../layouts/PaginationPerPage';
import {useDeferredValue} from 'react';
import Loading from '../../layouts/Loading';
import CronContext from '../../../context/CronContext';

const Cron = () => {
    const {
        total,
        data,
        isLoading,
        onChangePerPage, perPage,
        pageinate, page,
        onSearch, search,
    } = useContext(CronContext)

    const cronList = useDeferredValue(data);

    return isLoading ? (
        <Loading className="w-25"/>
    ) : (
        <main>
            <div className="table-head p-1">
                <h3>
                    Crons
                </h3>
                <form className="float-end" action="#">
                    <input type="text" placeholder="Search ..."
                           className="sr-input"
                           value={search}
                           onChange={e => onSearch(e)}
                    />
                    <button type="button" className="btn sr-btn" onClick={onSearch}>
                        <i className="bi bi-search"></i>
                    </button>
                </form>
            </div>
            <PaginationPerPage
                perPage={perPage}
                total={total}
                pageinate={pageinate}
                currentPage={page}
                onChangePerPage={onChangePerPage}
            />
            <table className="table table-striped data-table table-hover p-2">
                <thead className="table-success">
                <tr>
                    <th scope="col" className="hidden-mobile">#</th>
                    <th scope="col" className="hidden-mobile">Start</th>
                    <th scope="col" className="hidden-mobile">End</th>
                    <th scope="col" className="hidden-mobile">Spent</th>
                    <th scope="col" className="hidden-mobile w-50">Content</th>
                    <th scope="col" className="show-mobile hidden-tablet">Cron History</th>
                </tr>
                </thead>
                <tbody>
                {cronList && cronList.length ? (
                    cronList.map((cron, index) => (
                        <tr key={index}>
                            <th scope="row" className="hidden-mobile">{index + 1}</th>
                            <td className="hidden-mobile">{cron.start_time}</td>
                            <td className="hidden-mobile">{cron.end_time}</td>
                            <td className="hidden-mobile">{cron.runtime} seconds</td>
                            <td className="hidden-mobile w-50">
                                {JSON.parse(cron.content).project_ids
                                    ? <><b className="text-success">Project Ids
                                        Changed: </b><span>{JSON.parse(cron.content).project_ids.join(' , ')}</span></>
                                    : 'no change'}
                                {JSON.parse(cron.content).error
                                    ? <b className="text-success">Error: {JSON.parse(cron.content).error}</b>
                                    : ''}
                            </td>
                            <td className="show-mobile hidden-tablet">
                                <p><b>#</b>{index + 1}. Spent: {cron.runtime} seconds</p>
                                <p className="text-warning"><b
                                    className="text-primary">Runtime: </b><br/>{cron.start_time} - {cron.end_time}</p>
                                <p className="text-primary">{cron.action}</p>
                                <p>
                                    {JSON.parse(cron.content).project_ids
                                        ? <><b className="text-success">Project Ids
                                            Changed: </b><span>{JSON.parse(cron.content).project_ids.join(' , ')}</span></>
                                        : 'no change'}
                                    {JSON.parse(cron.content).error
                                        ? <b className="text-success">Error: {JSON.parse(cron.content).error}</b>
                                        : ''}
                                </p>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={6} className="hidden-mobile">There are no data.</td>
                        <td className="show-mobile hidden-tablet">There are no data.</td>
                    </tr>
                )}

                </tbody>
            </table>
        </main>
    )
}

export default Cron
