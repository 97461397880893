import React, {useState, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import UserContext from "./UserContext";
import UserService from "../libs/service/UserService";
import moment from "moment-timezone";
import {allTimezones} from "react-timezone-select";

export const UserProvider = ({children}) => {
    const navigate = useNavigate()
    // const [authMail, setAuthMail] = useState([]);
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [userEdit, setUserEdit] = useState({user: {}, isEdit: false});
    const [params, setParams] = useState({})
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false)

    const closeModal = () => {
        setIsOpen(false)
        setUserEdit({isEdit: false});
    }
    const openForm = () => {
        setUserEdit({isEdit: false});
        setIsOpen(true)
    }
    const editUserFnc = (user) => {
        //pass item info to form edit
        setUserEdit({user, isEdit: true});
        setIsOpen(true)
    };

    /***Paging ACTION***/
    const pageinate = (pageNumber) => {
        pageNumber = pageNumber ? pageNumber : 1
        setPage(pageNumber)
        const newParams = params
        newParams.page = pageNumber
        newParams.perPage = perPage
        newParams.search = search
        setParams(newParams)
    }

    // on change per page
    const onChangePerPage = (e) => {
        const newParams = params
        newParams.perPage = parseInt(e.target.value)
        newParams.page = 1
        setPerPage(parseInt(e.target.value))
    }

    const onSearch = (e) => {
        e.preventDefault()
        const newParams = params
        newParams.search = e.target.value
        newParams.page = 1
        newParams.perPage = perPage

        setSearch(e.target.value)
        setParams(newParams)
    }

    //timezone
    const [tz, setTz] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [datetime, setDatetime] = useState(moment());
    useMemo(() => {
        const tzValue = tz.value ?? tz; //??
        setDatetime(datetime.tz(tzValue));
    }, [tz, datetime]);

    //call API
    const fetchUsers = () => {
        const res = UserService.getUserList(params);
        res.then(
            (response) => {
                if (response.status === 401) {
                    localStorage.clear()
                    navigate('/')
                    navigate(0)
                }
                setUsers(response.data.data)
                setTotal(response.data.total)
                setIsLoading(false)
            }
            , (error) => {
                //Unauthenticated.
                if (error.response.status === 401) {
                    localStorage.clear()
                    navigate('/')
                    navigate(0)

                    //call api refresh token.
                    //UserService.refreshToken()
                }
                setIsLoading(false)
            }
        )

        return users;
    };

    const reloadUsers = () => {
        const newParams = params
        newParams.search = search
        newParams.page = page
        newParams.perPage = perPage
        setParams(newParams)
        fetchUsers()
        setErrorMessage('')
    }

    // user form
    const addUser = (newUser) => {
        setIsLoading(true)
        const res = UserService.createUser(newUser)
        res.then((response) => {
            reloadUsers()
            setIsLoading(false)
            //close popup
            setIsOpen(false)
            setUserEdit({isEdit: false});

        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setIsLoading(false)
        })
    };

    const updateUser = (id, user) => {
        setIsLoading(true)
        const res = UserService.updateUser(id, user)
        res.then((response) => {
            reloadUsers()
            setIsLoading(false)
            //close popup
            setIsOpen(false)
            setUserEdit({isEdit: false});
        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setIsLoading(false)
        })
    };

    const deleteUser = (id) => {
        setIsLoading(true)
        const res = UserService.deleteUser(id)
        res.then(response => {
            // console.log(response)
            setIsLoading(false)
            reloadUsers()
        }).catch(error => {
            // console.log(error)
            setIsLoading(false)
        })
    };

    const updateLeader = (id, isLeader) => {
        setIsLoading(true)
        const res = UserService.updateUser(id, {is_leader: isLeader})
        res.then(response => {
            // console.log(response)
            setIsLoading(false)
            reloadUsers()
        }).catch(error => {
            // console.log(error)
            setIsLoading(false)
        })
    }

    //Load List
    useEffect(() => {
        fetchUsers()

        return () => {
            fetchUsers()
        };
    }, [perPage, page, search])

    //ke ra ai thich sai cai nao thi sai
    const shareProvider = {
        total,
        users,
        isLoading,
        addUser,
        updateUser,
        deleteUser,
        editUserFnc,
        errorMessage,
        page,
        pageinate,
        perPage,
        onChangePerPage,
        search,
        onSearch,
        fetchUsers,
        isOpen,
        closeModal,
        openForm,
        userEdit,
        tz,
        setTz,
        datetime,
        allTimezones,
        updateLeader
    }
    return (
        <UserContext.Provider value={shareProvider}>
            {children}
        </UserContext.Provider>
    );
};
