import clientInstance from "./clientInstance"

const ProjectTypeService = {
    getProjectTypeList: async (params) => {
        return await clientInstance.get(
            '/project-type',
            {
                params: params,
            }
        )
    },

    getProjectType: async (id) => {
        return await clientInstance.get(
            '/project-type/' + id
        )
    },

    createProjectType: async (params) => {
        return await clientInstance.post(
            '/project-type',
            params
        )
    },

    updateProjectType: async (id, params) => {
        return await clientInstance.put(
            '/project-type/' + id,
            params
        )
    },

    deleteProjectType: async (id) => {
        return await clientInstance.delete(
            '/project-type/' + id
        )
    }
}

export default ProjectTypeService
