import React from 'react'
import Global from '../../libs/Global'

const MenuAdmin = (props) => {
    const {
        menu, onClickMenu, auth,
    } = props

    return auth && auth.user && auth.status === 'success' && Global.isSuperAdmin(auth.user.type) ? (
        <header className="navbar navbar-expand-lg navbar-expand-md">
            <div className="container">
                <button className="bg-success navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className={menu === 'user' ? "nav-link link-primary active" : "nav-link"}
                               aria-current="page"
                               href="user"
                               onClick={() => onClickMenu("user")}
                            ><i className="bi bi-people-fill"></i> Account</a>
                        </li>
                        <li className="nav-item">
                            <a className={menu === 'project' ? "nav-link link-primary active" : "nav-link"}
                               href="project"
                               onClick={() => onClickMenu("project")}
                            ><i className="bi bi-person-workspace"></i> Project</a>
                        </li>
                        <li className="nav-item">
                            <a className={menu === 'project-type' ? "nav-link link-primary active" : "nav-link"}
                               href="project-type"
                               onClick={() => onClickMenu("project-type")}
                            ><i className="bi bi-person-lines-fill"></i> Project Type</a>
                        </li>
                        <li className="nav-item">
                            <a className={menu === 'project-log' ? "nav-link link-primary active" : "nav-link"}
                               href="project-log"
                               onClick={() => {
                                   onClickMenu("project-log")
                               }}
                            ><i className="bi bi-person-rolodex"></i> Project Log</a>
                        </li>
                        <li className="nav-item">
                            <a className={menu === 'cron' ? "nav-link link-primary active" : "nav-link"}
                               href="cron"
                               onClick={() => {
                                   onClickMenu("cron")
                               }}
                            ><i className="bi bi-robot"></i> CRON</a>
                        </li>
                        <li className="nav-item">
                            <a className={menu === 'client' ? "nav-link link-primary active" : "nav-link"}
                               href="client"
                               onClick={() => {
                                   onClickMenu("client")
                               }}
                            ><i className="bi bi-person-hearts"></i> Client</a>
                        </li>
                        <div className="dropdown nav-item">
                            <a className={menu === 'setting' ? "dropdown-toggle nav-link link-primary active" : "dropdown-toggle nav-link"}
                               data-bs-toggle="dropdown"
                               href="#"
                               onClick={() => {
                                   onClickMenu("setting")
                               }}
                            ><i className="bi bi-gear"></i> Setting</a>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item"
                                       href="holiday"
                                       onClick={() => {
                                           onClickMenu("setting")
                                       }}>Holiday</a>
                                </li>
                                {/* <li><a className="dropdown-item" href="#">Work Hour</a></li> */}
                            </ul>
                        </div>
                    </ul>
                </div>
            </div>
        </header>
    ) : (
        ''
    )

}

export default MenuAdmin
