import React, {useContext, useEffect, useState} from 'react'
import AuthContext from '../../context/AuthContext';
import Global from '../../libs/Global';
import logo from '../../assets/images/logo.png'
import MenuAdmin from './MenuAdmin';

const Header = () => {
    const {
        auth,
        toggleAuth
    } = useContext(AuthContext);

    const [menu, setMenu] = useState('');

    function onClickMenu(selectedMenu) {
        setMenu(selectedMenu)
        localStorage.setItem('selectedMenu', selectedMenu)
    }

    useEffect(() => {
        setMenu(
            localStorage.getItem('selectedMenu')
                ? localStorage.getItem('selectedMenu') : ""
        )
    }, [])

    return (
        <div className="site-header mt-4">
            <MenuAdmin menu={menu} setMenu={setMenu} onClickMenu={onClickMenu} auth={auth}/>

            <header className="navbar navbar-expand-lg bg-light">
                <div className="container">
                    <a href="/" className="navbar-brand w-25"
                       onClick={() => onClickMenu("homepage")}
                       title={Global.brandName()}
                    >
                        {Global.logo(logo)}
                    </a>

                    {/* <ul className="nav w-25 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"></ul> */}

                    {auth.status === 'success' ? (
                            /*
                            <div className="text-end dropdown mb-lg-0 me-lg-3">

                                <a href="#" type="button"
                                    className="d-block link-primary text-decoration-none dropdown-toggle fs-1 text-success"
                                    id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-person-circle text-success link-primary fs-1"></i>
                                </a>

                                <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser">
                                    <li><a className="dropdown-item" href="/"><i className="bi bi-person">{" "}</i>{auth.user.name}</a></li>
                                    <li><a className="dropdown-item" href="/"><i className="bi bi-envelope">{" "}</i>{auth.user.email}</a></li>
                                    <li><a className="dropdown-item" href="/">
                                        <i className="bi bi-globe2">{" "}</i>
                                        {
                                            auth.user.timezone ? auth.user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                                        }</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="/" onClick={toggleAuth}><i className="bi bi-repeat">{" "}</i>Sign out</a></li>
                                </ul>
                            </div>
    */
                            <div className="btn-group">
                                <button type="button" data-bs-toggle="dropdown" data-bs-display="static"
                                        aria-expanded="false"
                                        className="btn btn-white dropdown-toggle text-decoration-none border-0 link-success">
                                    <i className="bi bi-person-circle text-success fs-1 link-primary"></i>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-lg-end dropdown-menu-end">
                                    <li><a className="dropdown-item" href="/"><i
                                        className="bi bi-person">{" "}</i>{auth.user.name}</a></li>
                                    <li><a className="dropdown-item" href="/"><i
                                        className="bi bi-envelope">{" "}</i>{auth.user.email}</a></li>
                                    <li><a className="dropdown-item" href="/">
                                        <i className="bi bi-globe2">{" "}</i>
                                        {
                                            auth.user.timezone ? auth.user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                                        }</a></li>
                                    <li>
                                        <hr className="dropdown-divider"/>
                                    </li>
                                    <li><a className="dropdown-item link-primary" href="/" onClick={toggleAuth}>
                                        <i className="bi bi-arrow-repeat">{" "}</i>Sign out</a>
                                    </li>
                                </ul>
                            </div>


                        ) :
                        ''
                    }
                </div>
            </header>
        </div>

    )
}

export default Header
