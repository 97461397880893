import {ceil, floor} from 'lodash';
import moment from "moment"
import Global from './Global';
//import { Base64 } from "js-base64";
export default {
    formatCurrency: function (num) {

        return '$' + Number(num.toFixed(2)).toLocaleString() + ' ';
    },

    formatDatetime: function (date) {

        return date.replace(/T/, ' ').replace(/\..+/, '')
    },

    formatShortDatetime: function (date) {
        return date;
    },
    getDateTime: function (datetime, tz = 'en-US') {
        const DATE_OPTIONS = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
        return new Date(datetime).toLocaleDateString(tz, DATE_OPTIONS)
    },
    // For get data converted tz from DB
    convertedUTC: function (datetime) {
        var format = 'YYYY-MM-DD HH:mm:ss';
        return moment.utc(datetime, format).format(format)
    },
    convertedDateUTC: function (datetime) {
        var format = 'YYYY-MM-DD';
        return moment.utc(datetime, format).format(format)
    },

    getMinutes: function () {
        // var format = 'mm:ss';
        // return moment.utc(new Date(), format).format(format)
        return new Date().getMinutes() + ":" + new Date().getSeconds()
    },

    //new function datetime convert from UTC to timezone
    convertUTC: function (datetime, timezone) {
        var format = 'YYYY-MM-DD HH:mm:ss';
        return moment.utc(datetime, format).tz(timezone).format(format)
    },

    convertDateUTC: function (datetime, timezone) {
        var format = 'YYYY-MM-DD';
        return moment.utc(datetime, format).tz(timezone).format(format)
    },

    datetimePickerUTC: function (datetime, timezone) {
        var format = 'YYYY-MM-DD HH:mm:ss';
        return moment.utc(datetime, format).tz(timezone).format(format)
    },

    /**
     * load Workhour left from Today
     * @param {string} startDate
     * @returns
     */
    worktimeLeft: function (startDate) {
        var date1 = new Date(startDate);
        var date2 = new Date();
        var cal = 1
        var Difference_In_Time = 0
        if (date2.getTime() >= date1.getTime()) {
            // To calculate the time difference of two dates
            Difference_In_Time = date2.getTime() - date1.getTime();

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            var days = floor(Difference_In_Days);
            var hours = floor((Difference_In_Days - floor(Difference_In_Days)) * 24)
            var minutes = ceil(((Difference_In_Days - floor(Difference_In_Days)) * 24 - hours) * 60)

            return cal > 0
                ? days + 'D ' + hours + ':' + minutes
                : days > 0
                    ? cal * days + 'D ' + hours + ':' + minutes
                    : hours > 0
                        ? cal * hours + ':' + minutes
                        : minutes > 0
                            ? '-00:' + minutes : '-'
        }
        // To calculate the time difference of two dates
        // else {
        //     cal = -1
        //     Difference_In_Time = date1.getTime() - date2.getTime()
        // }

        return '0'

    },

    /**
     *
     * @param {string} startDate
     * @param {string} endDate
     * @returns
     */
    calWorktimeLeft: function (startDate, endDate) {
        var date1 = new Date(startDate);
        var date2 = new Date(endDate);

        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        var days = floor(Difference_In_Days);
        var hours = floor((Difference_In_Days - floor(Difference_In_Days)) * 24)
        var minutes = floor(((Difference_In_Days - floor(Difference_In_Days)) * 24 - hours) * 60)

        return days + 'D ' + (hours ? hours : '00') + ':' + (minutes ? minutes : '00')
    },

    worktimeLeftPercent: function (startDate, endDate) {
        var date = new Date();
        var date1 = new Date(startDate);
        var date2 = new Date(endDate);

        // To calculate the time difference of two dates
        var Difference_In_Time = date.getTime() - date1.getTime();
        var Difference_In_Time1 = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        // var Difference_In_Days1 = Difference_In_Time1 / (1000 * 3600 * 24);

        return Difference_In_Time > Difference_In_Time1 ? 100 : ceil(100 * Difference_In_Time / Difference_In_Time1)
    },

    projectStatus: (status) => {
        return status === 1 || status === '1'
            ? Global.projectStatusInProgress(true)
            : status === 2 || status === '2'
                ? Global.projectStatusClosed(true)
                : Global.projectStatusOpen(true)
    },
}
