import clientInstance from "./clientInstance"

const SettingService = {
    getCountries: async (params) => {
        return await clientInstance.get(
            '/countries/create',
            {
                params: params,
            }
        )
    },
    getSettingList: async (params) => {
        return await clientInstance.get(
            '/setting',
            {
                params: params,
            }
        )
    },

    getSetting: async (id) => {
        return await clientInstance.get(
            '/setting/' + id
        )
    },

    createSetting: async (params) => {
        return await clientInstance.post(
            '/setting',
            params
        )
    },

    updateSetting: async (id, params) => {
        return await clientInstance.put(
            '/setting/' + id,
            params
        )
    },

    deleteSetting: async (id) => {
        return await clientInstance.delete(
            '/setting/' + id
        )
    }
}

export default SettingService
