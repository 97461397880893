import React, {useEffect, useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import Loading from '../../layouts/Loading';
import ClientContext from '../../../context/ClientContext';
// import TimezoneSelect from "react-timezone-select";
import useWindowDimensions from '../../../libs/useWindowDimensions'
import Global from '../../../libs/Global';
import {DefaultEditor, BtnBold, BtnItalic, Editor, Toolbar} from 'react-simple-wysiwyg';

const Client = (props) => {
    const {
        isOpen, closeModal,
        clientEdit,
        // tz, setTz, datetime, allTimezones,
        errorMessage,
        addClient,
        updateClient
    } = useContext(ClientContext)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [content, setContent] = useState('');

    // Customize your editor with css rules
    var editorStyle = {
        overflow: 'auto',
        height: 150,
        maxHeight: 150
    }
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [fullscreen, setFullscreen] = useState('')
    const {modelSize} = useWindowDimensions()

    const submitFormHandler = (client) => {
        if (clientEdit.isEdit) {
            updateClient(clientEdit.client.id, client);
        } else {
            addClient(client);
        }
    }

    // Generate JSX code for error message
    const renderErrorMessage = (message, className = "text-danger") => (
        <div className={className}>
            {message}
        </div>
    );

    const changeContent = (e) => {
        // console.log(e)
        // e.defaultPrevent()
        setContent(e.target.value)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            email: email,
            phone: phone,
            address: address,
            content: content
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required"),
            email: Yup.string()
                .email("Invalid email format")
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required"),
            phone: Yup.string()
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required"),
            address: Yup.string()
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required")
        }),
        onSubmit: values => {
            const param = JSON.stringify(values, null, 2);
            submitFormHandler(JSON.parse(param))
            setIsSubmitted(true);
        }
    });

    useEffect(() => {
        if (clientEdit.isEdit) {
            setName(clientEdit.client.name)
            setEmail(clientEdit.client.email)
            setPhone(clientEdit.client.phone)
            setAddress(clientEdit.client.address)
            setContent(clientEdit.client.content)

            // setIsProjectManager(clientEdit.client.is_leader)
        } else {
            setName('')
            setEmail('')
            setPhone('')
            setAddress('')
            setContent('')
            // setIsProjectManager(0)
        }
    }, [clientEdit]);

    const clientForm = () => (
        <Modal dialogClassName={modelSize + " modal-dialog-centered modal-dialog-scrollable " + fullscreen}
               show={isOpen} onHide={closeModal} animation={true}>
            <Modal.Header closeButton className="manager-head bg-light">
                <h3 className="card-title w-100">
                    {clientEdit.isEdit ? "Update client" : "Add New client"}
                </h3>
                <button className="float-end me-2 btn" onClick={() => {
                    setFullscreen(fullscreen === 'modal-fullscreen' ? '' : 'modal-fullscreen')
                }}>
                    <i className={fullscreen === 'modal-fullscreen' ? "bi-arrows-angle-contract" : "bi bi-arrows-fullscreen"}></i>
                </button>
            </Modal.Header>
            <Modal.Body style={{height: "90%"}}>
                <div className="d-flex justify-content-center h-100 w-100">
                    <div className="container-fluid card register mt-3">
                        <div className="card-body">
                            {isSubmitted ? (
                                <Loading className="w-25"/>
                            ) : ''}
                            {renderErrorMessage(errorMessage)}
                            {/* {renderErrorMessage(successMessages, "text-success")} */}
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Full Name" name="name"
                                           value={formik.values.name}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.name && formik.touched.name &&
                                        renderErrorMessage(formik.errors.name)
                                    }
                                    <label className="control-label" htmlFor="name">
                                        <i className="bi bi-person">{" "}</i>Full Name

                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    {/* {clientEdit.isEdit
                                        ? <input type="text" className="form-control" placeholder="Email" name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            readOnly={true} />
                                        : <input type="text" className="form-control" placeholder="Email" name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange} />
                                    } */}
                                    <input type="text" className="form-control" placeholder="Email" name="email"
                                           value={formik.values.email}
                                           onChange={formik.handleChange}/>
                                    {formik.errors.email && formik.touched.email &&
                                        renderErrorMessage(formik.errors.email)}

                                    <label className="control-label" htmlFor="email">
                                        <i className="bi bi-envelope">{" "}</i> Email
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Phone number" name="phone"
                                           value={formik.values.phone}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.phone && formik.touched.phone &&
                                        renderErrorMessage(formik.errors.phone)}
                                    <label className="control-label" htmlFor="phone">
                                        <i className="bi bi-phone"></i> Phone
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Enter Address"
                                           name="address"
                                           value={formik.values.address}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.address && formik.touched.address &&
                                        renderErrorMessage(formik.errors.address)}
                                    <label className="control-label" htmlFor="address">
                                        <i className="bi bi-pin-map"></i> Address
                                    </label>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="content" className="label-control">Detail</label>

                                    <DefaultEditor
                                        id="content"
                                        name="content"
                                        value={formik.values.content}
                                        onChange={formik.handleChange}
                                        style={editorStyle}
                                    >
                                        <Toolbar>
                                            <BtnBold/>
                                            <BtnItalic/>
                                            <Editor/>
                                        </Toolbar>
                                    </DefaultEditor>
                                    {formik.errors.content && formik.touched.content &&
                                        renderErrorMessage(formik.errors.content)
                                    }
                                </div>

                                <div className="form-group text-end mt-2">
                                    <button type="submit" className="btn btn-success">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
    );

    return clientForm()

}

export default Client
