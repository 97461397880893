import React, {useCallback, useEffect, useContext, useState, useDeferredValue} from 'react';
import {useFormik, useFormikContext} from 'formik';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import Loading from '../../layouts/Loading';
import ProjectContext from '../../../context/ProjectContext';
import useWindowDimensions from '../../../libs/useWindowDimensions'
import DateTimePicker from 'react-datetime-picker';
import {DefaultEditor, BtnBold, BtnItalic, Editor, Toolbar} from 'react-simple-wysiwyg';
import Util from '../../../libs/Util';
import Global from '../../../libs/Global';
import ProjectService from '../../../libs/service/ProjectService';
import TotalHours from '../../layouts/TotalHours';
import DatePicker from "react-datepicker";

export const DisplayFormikState = props =>
    <div style={{ margin: '1rem 0' }}>
        <h3 style={{ fontFamily: 'monospace' }} />
        <pre
            style={{
                background: '#f6f8fa',
                fontSize: '.65rem',
                padding: '.5rem',
            }}
        >
      <strong>props</strong> ={' '}
            {JSON.stringify(props, null, 2)}
    </pre>
    </div>;

const Project = (props) => {
    const {
        isOpen, closeModal,
        projectEdit,
        errorMessage,
        addProject,
        updateProject,
        tz,
        projectTypes,
        btnRef
    } = useContext(ProjectContext)

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isChangeDate, setIsChangeDate] = useState(false)
    const [open, setOpen] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [link, setLink] = useState('')
    const [typeId, setTypeId] = useState(0)
    const [status, setStatus] = useState(0)
    const [overtime, setOvertime] = useState(0);
    const [initHours, setInitHours] = useState(0);
    const [totalHours, setTotalHours] = useState(0);
    const reloadTotalHours = useDeferredValue()

    // Customize your editor with css rules
    var editorStyle = {
        overflow: 'auto',
        height: 150,
        maxHeight: 150
    }
    const [fullscreen, setFullscreen] = useState('')
    const {modelSize} = useWindowDimensions()

    // Generate JSX code for error message
    const renderErrorMessage = (message, className = "text-danger") => (
        <div className={className}>
            {message}
        </div>
    );

    const formik = useFormik({
        initialValues: {
            tz: tz,
            title: title,
            type_id: typeId,
            start_date: startDate,
            end_date: endDate,
            overtime: overtime,
            init_hours: initHours,
            status: status,
            link: link,
            description: description
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            title: Yup.string()
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required"),
            type_id: Yup.number()
                .min(1, 'Required'),
            start_date: Yup.string().nullable(true),
            end_date: Yup.string().nullable(true),
            status: Yup.number(),
            link: Yup.string().nullable(true),
            overtime: Yup.number().min(0, 'Required number >= 0'),
            init_hours: Yup.number().min(0, 'Required number >= 0'),
        }),
        onSubmit: values => {
            if (btnRef.current) {
                btnRef.current.setAttribute("disabled", "disabled")
            }
            setIsSubmitted(true);
            const param = JSON.stringify(values, null, 2);
            submitFormHandler(JSON.parse(param))
        }
    });

    const submitFormHandler = (project) => {
        if (projectEdit.isEdit) {
            updateProject(projectEdit.project.id, project);
        } else {
            addProject(project);
        }
    }

    const handleCalTotalHours = () => {
        var params = {
            startDate: Util.convertUTC(startDate, tz),
            endDate: Util.convertUTC(endDate, tz),
            tz: tz
        }
        const res = ProjectService.totalHoursProject(params)
        res.then((response) => {
            setIsChangeDate(true)
            // reloadTotalHours.current = response.data.totalHours
            // localStorage.setItem("totalHours", JSON.stringify(response.data))
            setTotalHours(response.data.totalHours)
            renderTotalHours(response.data.totalHours)
        }).catch((error) => {
            console.log(error)
            setIsChangeDate(false)
        })
    }
    const handleChange = (date) => {
        setEndDate(date)
        setOpen(true)
    }

    // Generate JSX code for error message
    const renderTotalHours = (total) => (
        <TotalHours total={total}/>
    );

    useEffect(() => {
        //render form
        if (projectEdit.isEdit) {
            // console.log(projectEdit)
            setTitle(projectEdit.project.title)
            setTypeId(projectEdit.project.type_id)
            setLink(projectEdit.project.link ? projectEdit.project.link : '')
            setDescription(projectEdit.project.description ? projectEdit.project.description : '')
            setStatus(projectEdit.project.status)
            //start date & end date
            setStartDate(new Date(projectEdit.project.start_date)) //new Date(Util.convertedUTC(projectEdit.project.start_date)))
            setEndDate(new Date(projectEdit.project.end_date)) // new Date(Util.convertedUTC(projectEdit.project.end_date)))
            setOvertime(projectEdit.project.overtime ? projectEdit.project.overtime : 0)
            setInitHours(projectEdit.project.init_hours ? projectEdit.project.init_hours : 0)
            setTotalHours(projectEdit.project.totalHours ? projectEdit.project.totalHours : 0)
        } else {
            setTitle('')
            setTypeId(0)
            setDescription('')
            setLink('')
            setStatus(0)
            setStartDate(new Date())
            setEndDate(new Date())
            setOvertime(0)
            setInitHours(0)
            setTotalHours(0)
            formik.resetForm()
        }

        return () => {
            //
        }
    }, [projectEdit]);


    const projectForm = () => (
        <Modal dialogClassName={modelSize + " modal-dialog-centered modal-dialog-scrollable " + fullscreen}
               show={isOpen} onHide={closeModal} animation={true}>
            <Modal.Header closeButton className="manager-head bg-light">
                <h3 className="card-title w-100">
                    {projectEdit.isEdit ? "Update Project" : "Add New Project"}
                </h3>
                <button className="float-end me-2 btn" onClick={() => {
                    setFullscreen(fullscreen === 'modal-fullscreen' ? '' : 'modal-fullscreen')
                }}>
                    <i className={fullscreen === 'modal-fullscreen' ? "bi-arrows-angle-contract" : "bi bi-arrows-fullscreen"}></i>
                </button>
            </Modal.Header>
            <Modal.Body style={{height: "90%"}}>
                <div className="card-body">
                    {isSubmitted ? (
                        <Loading className="w-25"/>
                    ) : ''}
                    {renderErrorMessage(errorMessage)}
                    <form onSubmit={formik.handleSubmit}>

                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" placeholder="Project name" name="title"
                                   value={formik.values.title}
                                   onChange={formik.handleChange}
                            />
                            {formik.errors.title && formik.touched.title &&
                                renderErrorMessage(formik.errors.title)}

                            <label className="control-label" htmlFor="title">
                                <i className="bi bi-clipboard">{" "}</i>Project Name
                            </label>
                        </div>
                        <div className="form-floating mb-3">
                            <select className="form-select" id="type_id" name="type_id"
                                    onChange={formik.handleChange}
                                    value={formik.values.type_id}>
                                <option value="0">Select project type</option>
                                {projectTypes ? projectTypes.map((item, index) => (
                                    <option key={index} value={item.id} label={item.name}/>
                                )) : ''}
                            </select>
                            {formik.errors.type_id && formik.touched.type_id &&
                                renderErrorMessage(formik.errors.type_id)}
                            <label className="control-label" htmlFor="mtype_id">
                                <i className="bi-clipboard-check">{" "}</i>Project Type
                            </label>
                        </div>

                        <div className="form-floating mb-3">
                            <div className="form-control">
                                <DateTimePicker name="start_date"
                                                value={formik.values.start_date}
                                                onChange={setStartDate}
                                                format="y-MM-dd HH:mm:ss"
                                />
                                {formik.errors.start_date && formik.touched.start_date &&
                                    renderErrorMessage(formik.errors.start_date)}
                            </div>
                            <label className="label-control" htmlFor="start_date">
                                <i className="bi bi-alarm">{" "}</i>Start Date
                            </label>
                        </div>

                        <div className="form-floating mb-3">
                            <div className="form-control" onBlur={() => setOpen(false)}>
                                <DateTimePicker name="end_date"
                                                value={formik.values.end_date}
                                                onChange={setEndDate}
                                                onCalendarClose={handleCalTotalHours}
                                                minDate={startDate}
                                                format="y-MM-dd HH:mm:ss"
                                />
                                {formik.errors.end_date && formik.touched.end_date &&
                                    renderErrorMessage(formik.errors.end_date)}
                            </div>
                            <label className="label-control" htmlFor="end_date">
                                <i className="bi bi-alarm-fill">{" "}</i>End Date
                            </label>
                        </div>

                        {/* {renderTotalHours(totalHours)} */}
                        <TotalHours total={totalHours}/>

                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" placeholder="Over time"
                                   name="overtime"
                                   onChange={formik.handleChange}
                                   value={formik.values.overtime}
                            />
                            {formik.errors.overtime && formik.touched.overtime &&
                                renderErrorMessage(formik.errors.overtime)}

                            <label className="control-label" htmlFor="overtime">
                                <i className="bi bi-moon-stars">{" "}</i>Over time
                            </label>
                        </div>

                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" placeholder="Init hours"
                                   name="init_hours"
                                   onChange={formik.handleChange}
                                   value={formik.values.init_hours}
                            />
                            {formik.errors.init_hours && formik.touched.init_hours &&
                                renderErrorMessage(formik.errors.init_hours)}

                            <label className="control-label" htmlFor="Init hours">
                                <i className="bi bi-cart4">{" "}</i>Init hours
                            </label>
                        </div>

                        <div className="form-floating mb-3">
                            <select className="form-select" id="status"
                                    name="status"
                                    onChange={formik.handleChange}
                                    value={formik.values.status}>
                                <option value={0} label={Global.projectStatusOpen(true)}/>
                                <option value={1} label={Global.projectStatusInProgress(true)}/>
                                <option value={2} label={Global.projectStatusClosed(true)}/>
                            </select>
                            {formik.errors.status && formik.touched.status &&
                                renderErrorMessage(formik.errors.status)}

                            <label className="label-control" htmlFor="status">
                                <i className="bi-exclamation-square">{" "}</i>Status
                            </label>
                        </div>

                        <div className="form-floating mb-3">
                            <input type="text" className="form-control"
                                   placeholder="http://domain.com" name="link"
                                   value={formik.values.link}
                                   onChange={formik.handleChange}/>
                            {formik.errors.link && formik.touched.link &&
                                renderErrorMessage(formik.errors.link)}
                            <label className="control-label" htmlFor="link">
                                <i className="bi bi-globe">{" "}</i>Website
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description" className="label-control">Description</label>

                            <DefaultEditor
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                style={editorStyle}
                            >
                                <Toolbar>
                                    <BtnBold/>
                                    <BtnItalic/>
                                    <Editor/>
                                </Toolbar>
                            </DefaultEditor>
                            {formik.errors.description && formik.touched.description &&
                                renderErrorMessage(formik.errors.description)
                            }
                        </div>

                        <div className="form-group text-end">
                            <button type="submit" className="btn btn-success" ref={btnRef}>
                                Save
                            </button>
                        </div>
                        {/*<DisplayFormikState {...formik} />*/}
                    </form>
                </div>

            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */
            }
        </Modal>
    )

    return projectForm()
}

export default Project
