import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css?v=123';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css"
import AuthProvider from './context/AuthProvider';

//Auto check project on 10s
function emitCheckProject(page) {
    setInterval(() => {
        window.dispatchEvent(
            new CustomEvent(`loadProject-${page}`, {
                detail: `Check project status ${page}`
            })
        )
    }, 60000) //60s

    setInterval(() => {
        window.dispatchEvent(
            new CustomEvent(`loadProjectNotification-${page}`, {
                detail: `Check project status notification ${page}`
            })
        )
        // }, 300000) //300s = 5m
        // }, 10000) //10s
    }, 60000) //60s = 1m
}

emitCheckProject(1)

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute['href']
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
