import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import LogService from "../libs/service/LogService";
import ProjectLogContext from "./ProjectLogContext";

export const ProjectLogProvider = ({children}) => {
    const navigate = useNavigate()

    //stage paging
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [params, setParams] = useState({})
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [errorMessage, setErrorMessage] = useState('');

    /***Paging ACTION***/
        //on change page
    const pageinate = (pageNumber) => {
            pageNumber = pageNumber ? pageNumber : 1
            setPage(pageNumber)
            const newParams = params
            newParams.page = pageNumber
            newParams.perPage = perPage
            newParams.search = search
            setParams(newParams)
        }

    //on change per page
    const onChangePerPage = (e) => {
        setPerPage(parseInt(e.target.value))
        const newParams = params
        newParams.perPage = parseInt(e.target.value)
        newParams.page = 1
    }
    const onSearch = (e) => {
        e.preventDefault()
        const newParams = params
        newParams.search = e.target.value
        newParams.page = 1
        newParams.perPage = perPage

        setSearch(e.target.value)
        setParams(newParams)
    }

    //Load List
    useEffect(() => {
        fetchProjectLog()

        return () => {
            fetchProjectLog()
        };
    }, [perPage, page, search])

    //call API
    const fetchProjectLog = () => {
        const res = LogService.getProjectLogList(params);
        res.then(
            (response) => {
                if (response.status === 401) {
                    // console.log(response.data.message)
                    localStorage.clear()
                    navigate('/')
                    navigate(0)
                }
                setTotal(response.data.total)
                setData(response.data.data)
                setIsLoading(false)
            }
            , (error) => {
                //Unauthenticated.
                if (error.response.status === 401) {
                    setTimeout(() => {
                        setErrorMessage(error.response.data.message)
                        localStorage.clear()
                        navigate('/')
                        navigate(0)
                    }, 200);
                    //call api refresh token.
                    //UserService.refreshToken()
                }

                setIsLoading(false)
            }
        )

        return data;
    };

    //ke ra ai thich sai cai nao thi sai
    const shareProvider = {
        total,
        data,
        isLoading,
        page,
        pageinate,
        perPage,
        onChangePerPage,
        search,
        onSearch,
        errorMessage,
        setErrorMessage
    }
    return (
        <ProjectLogContext.Provider value={shareProvider}>
            {children}
        </ProjectLogContext.Provider>
    );
};
