import React, {useContext} from 'react'
import AuthContext from '../../../context/AuthContext'
import {CronProvider} from '../../../context/CronProvider'
import Global from '../../../libs/Global'
import Cron from './Cron'

const CronManager = () => {
    const {auth} = useContext(AuthContext);

    return auth && auth.user && auth.user.type && Global.isSuperAdmin(auth.user.type)
        ? (
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                        <CronProvider>
                            <Cron/>
                        </CronProvider>
                    </div>
                </div>
            </div>
        )
        : Global.reLogin()
}

export default CronManager
