import React, {useState, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import ClientContext from "./ClientContext";
import ClientService from "../libs/service/ClientService";
import moment from "moment-timezone";
import {allTimezones} from "react-timezone-select";

export const ClientProvider = ({children}) => {
    const navigate = useNavigate()
    const [clients, setClients] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [clientEdit, setClientEdit] = useState({client: {}, isEdit: false});
    const [params, setParams] = useState({})
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false)

    const closeModal = () => {
        setIsOpen(false)
        setClientEdit({isEdit: false});
    }
    const openForm = () => {
        setClientEdit({isEdit: false});
        setIsOpen(true)
    }
    const editClientFnc = (client) => {
        //pass item info to form edit
        setClientEdit({client, isEdit: true});
        setIsOpen(true)
    };

    /***Paging ACTION***/
    const pageinate = (pageNumber) => {
        pageNumber = pageNumber ? pageNumber : 1
        setPage(pageNumber)
        const newParams = params
        newParams.page = pageNumber
        newParams.perPage = perPage
        newParams.search = search
        setParams(newParams)
    }

    // on change per page
    const onChangePerPage = (e) => {
        const newParams = params
        newParams.perPage = parseInt(e.target.value)
        newParams.page = 1
        setPerPage(parseInt(e.target.value))
    }

    const onSearch = (e) => {
        e.preventDefault()
        const newParams = params
        newParams.search = e.target.value
        newParams.page = 1
        newParams.perPage = perPage

        setSearch(e.target.value)
        setParams(newParams)
    }

    //timezone
    const [tz, setTz] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [datetime, setDatetime] = useState(moment());
    useMemo(() => {
        const tzValue = tz.value ?? tz; //??
        setDatetime(datetime.tz(tzValue));
    }, [tz, datetime]);

    //call API
    const fetchClients = () => {
        const res = ClientService.getClientList(params);
        res.then(
            (response) => {
                if (response.status === 401) {
                    localStorage.clear()
                    navigate('/')
                    navigate(0)
                }
                setClients(response.data.data)
                setTotal(response.data.total)
                setIsLoading(false)
            }
            , (error) => {
                //Unauthenticated.
                if (error.response.status === 401) {
                    localStorage.clear()
                    navigate('/')
                    navigate(0)
                }
                setIsLoading(false)
            }
        )

        return clients;
    };

    const reloadClients = () => {
        const newParams = params
        newParams.search = search
        newParams.page = page
        newParams.perPage = perPage
        setParams(newParams)
        fetchClients()
        setErrorMessage('')
    }

    // client form
    const addClient = (newClient) => {
        setIsLoading(true)
        const res = ClientService.createClient(newClient)
        res.then((response) => {
            reloadClients()
            setIsLoading(false)
            //close popup
            setIsOpen(false)
            setClientEdit({isEdit: false});

        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setIsLoading(false)
        })
    };

    const updateClient = (id, user) => {
        setIsLoading(true)
        const res = ClientService.updateClient(id, user)
        res.then((response) => {
            reloadClients()
            setIsLoading(false)
            //close popup
            setIsOpen(false)
            setClientEdit({isEdit: false});
        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setIsLoading(false)
        })
    };

    const deleteClient = (id) => {
        setIsLoading(true)
        const res = ClientService.deleteClient(id)
        res.then(response => {
            // console.log(response)
            setIsLoading(false)
            reloadClients()
        }).catch(error => {
            // console.log(error)
            setIsLoading(false)
        })
    };

    // const updateLeader = (id, isLeader) => {
    //     setIsLoading(true)
    //     const res = ClientService.updateClient(id, { is_leader: isLeader })
    //     res.then(response => {
    //         setIsLoading(false)
    //         reloadClients()
    //     }).catch(error => {
    //         setIsLoading(false)
    //     })
    // }

    //Load List
    useEffect(() => {
        fetchClients()

        return () => {
            fetchClients()
        };
    }, [perPage, page, search])

    //ke ra ai thich sai cai nao thi sai
    const shareProvider = {
        total,
        clients,
        isLoading,
        addClient,
        updateClient,
        deleteClient,
        editClientFnc,
        errorMessage,
        page,
        pageinate,
        perPage,
        onChangePerPage,
        search,
        onSearch,
        fetchClients,
        isOpen,
        closeModal,
        openForm,
        clientEdit,
        tz,
        setTz,
        datetime,
        allTimezones,
        // updateLeader
    }

    return (
        <ClientContext.Provider value={shareProvider}>
            {children}
        </ClientContext.Provider>
    );
};
