import React, {useEffect, useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import Loading from '../../layouts/Loading';
import ProjectContext from '../../../context/ProjectContext';
import useWindowDimensions from '../../../libs/useWindowDimensions'
import {DefaultEditor, BtnBold, BtnItalic, Editor, Toolbar} from 'react-simple-wysiwyg';
import Global from '../../../libs/Global';
import ClientService from "../../../libs/service/ClientService";
import {useNavigate} from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import Util from '../../../libs/Util';

const ProjectClient = () => {
    const {
        isOpenClient,
        closeModalClient,
        errorMessage,
        btnRefClient,
        projectAssignClient,
        saveProjectClients,
        renderErrorMessage,
        tz
    } = useContext(ProjectContext)

    const navigate = useNavigate()

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [projectTitle, setProjectTitle] = useState(0)
    const [projectId, setProjectId] = useState(0)
    const [clientId, setClientId] = useState(0)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [content, setContent] = useState('')
    const [budget, setBudget] = useState(0)

    const [clients, setClients] = useState([])
    const [projectClientId, setProjectClientId] = useState(0)

    // Customize your editor with css rules
    var editorStyle = {
        overflow: 'auto',
        height: 150,
        maxHeight: 150
    }
    const [fullscreen, setFullscreen] = useState('')
    const {modelSize} = useWindowDimensions()

    //edit form
    const formik = useFormik({
        initialValues: {
            id: projectClientId,
            tz: tz,
            project_id: projectId,
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
            budget: budget,
            content: content
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            client_id: Yup.number()
                .min(1, 'Required'),
            start_date: Yup.string().nullable(true),
            end_date: Yup.string().nullable(true)
        }),
        onSubmit: values => {
            if (btnRefClient.current) {
                btnRefClient.current.setAttribute("disabled", "disabled")
            }
            setIsSubmitted(true);
            const param = JSON.stringify(values, null, 2);
            submitFormHandler(JSON.parse(param))
        }
    });

    const submitFormHandler = (projectClients) => {
        //id: exist -> update else create new
        saveProjectClients(projectClients)
    }

    const loadClients = () => {
        const res = ClientService.getClients();
        res.then(response => {
            setClients(response.data)
        }).catch(error => {
            if (error.response.status === 401) { //Unauthenticated.
                localStorage.clear()
                navigate('/')
                navigate(0)
            } else {
                // console.log(error)
            }
        })
    }

    useEffect(() => {
        if (projectAssignClient && projectAssignClient.project && projectAssignClient.project.id) {
            setProjectId(projectAssignClient.project.id)
            setProjectTitle(projectAssignClient.project.title)
            if (projectAssignClient.isEdit) {
                setProjectClientId(projectAssignClient.project.projectClientId)
                setClientId(projectAssignClient.project.client_id)
                setStartDate(new Date(projectAssignClient.project.plan_start_date))
                setEndDate(new Date(projectAssignClient.project.plan_end_date))
                setBudget(projectAssignClient.project.budget)
                setContent(projectAssignClient.project.clientContent)
            } else {
                setProjectClientId(0)
                setClientId(0)
                setStartDate(new Date())
                setEndDate(new Date())
                setBudget(0)
                setContent('')
            }
        }

        loadClients()

        return () => {
            loadClients()
        }
    }, [projectAssignClient]);

    const projectClientForm = () => (
        <Modal dialogClassName={modelSize + " modal-dialog-centered modal-dialog-scrollable " + fullscreen}
               show={isOpenClient}
               onHide={closeModalClient} animation={true}>
            <Modal.Header closeButton className="manager-head bg-light">
                <h3 className="card-title w-100">
                    Add Client
                </h3>
                <button className="float-end me-2 btn"
                        onClick={() => {
                            setFullscreen(fullscreen === 'modal-fullscreen' ? '' : 'modal-fullscreen')
                        }}>
                    <i className={fullscreen === 'modal-fullscreen' ? "bi-arrows-angle-contract" : "bi bi-arrows-fullscreen"}></i>
                </button>
            </Modal.Header>
            <Modal.Body style={{height: "90%"}}>
                <div className="card-body">
                    {isSubmitted ? (
                        <Loading className="w-25"/>
                    ) : ''}

                    {Global.renderErrorMessage(errorMessage)}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control"
                                   value={projectTitle}
                                   readOnly={true}
                            />
                            <label className="control-label" htmlFor="title">
                                <i className="bi bi-clipboard">{" "}</i>Project Name
                            </label>
                        </div>
                        <div className="form-floating mb-3">
                            <select className="form-select" id="client_id"
                                    name="client_id"
                                    onChange={e => {
                                        formik.handleChange(e);
                                        setClientId(e.target.value)
                                    }}
                                    value={formik.values.client_id}>
                                <option value={0} label={"Select Client"}/>
                                {clients.map((item, key) => (
                                    <option key={key} value={item.id} label={item.name}/>
                                ))}
                            </select>
                            {formik.errors.client_id && formik.touched.client_id &&
                                Global.renderErrorMessage(formik.errors.client_id)}

                            <label className="label-control" htmlFor="client_id">
                                <i className="bi bi-person-circle fs-5 link-primary"></i> Select Client
                            </label>
                        </div>
                        <div className="form-floating mb-3">
                            <div className="form-control">
                                <DateTimePicker name="start_date"
                                                value={startDate}
                                                onChange={setStartDate}
                                                format="y-MM-dd HH:mm:ss"
                                />
                                {formik.errors.start_date && formik.touched.start_date &&
                                    renderErrorMessage(formik.errors.start_date)}
                            </div>
                            <label className="label-control" htmlFor="start_date">
                                <i className="bi bi-alarm">{" "}</i>Start Date
                            </label>
                        </div>
                        <div className="form-floating mb-3">
                            <div className="form-control">
                                <DateTimePicker name="end_date"
                                                value={endDate}
                                                onChange={setEndDate}
                                                minDate={startDate}
                                                format="y-MM-dd HH:mm:ss"
                                />
                                {formik.errors.end_date && formik.touched.end_date &&
                                    renderErrorMessage(formik.errors.end_date)}
                            </div>
                            <label className="label-control" htmlFor="end_date">
                                <i className="bi bi-alarm-fill">{" "}</i>End Date
                            </label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" placeholder="Budget hours"
                                   name="budget"
                                   onChange={formik.handleChange}
                                   value={formik.values.budget}
                            />
                            {formik.errors.budget && formik.touched.budget &&
                                renderErrorMessage(formik.errors.budget)}

                            <label className="control-label" htmlFor="Budget hours">
                                <i className="bi bi-cart4">{" "}</i> Budget hours
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="content" className="label-control">Content</label>
                            <DefaultEditor
                                id="content"
                                name="content"
                                value={formik.values.content}
                                onChange={formik.handleChange}
                                style={editorStyle}
                            >
                                <Toolbar>
                                    <BtnBold/>
                                    <BtnItalic/>
                                    <Editor/>
                                </Toolbar>
                            </DefaultEditor>
                            {formik.errors.content && formik.touched.content &&
                                Global.renderErrorMessage(formik.errors.content)
                            }
                        </div>

                        <div className="form-group text-end">
                            <button type="submit" className="btn btn-success" ref={btnRefClient}>
                                Save
                            </button>
                        </div>
                    </form>
                </div>

            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
    )

    return projectClientForm()
}

export default ProjectClient
