import React, {useEffect, useState} from 'react'
import AuthService from '../libs/service/AuthService';
import AuthContext from './AuthContext';

const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({})
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isAuthentiacted, setIsAuthentiacted] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    // load components
    // const [hasRenderUser, setRenderUser] = useState(false);
    // const [hasRenderProject, setRenderProject] = useState(false);
    // const [hasRenderProjectLog, setRenderProjectLog] = useState(false);
    // const [hasRenderProjectType, setRenderProjectType] = useState(false);
    // const [hasRenderCron, setRenderCron] = useState(false);

    // const onShowUser = React.useCallback(() => {
    //     setRenderUser(true)
    //     setRenderProject(false)
    //     setRenderProjectLog(false)
    //     setRenderProjectType(false)
    //     setRenderCron(false)
    // }, []);
    // const onShowProject = React.useCallback(() => {
    //     setRenderProject(true)
    //     setRenderUser(false)
    //     setRenderProjectLog(false)
    //     setRenderProjectType(false)
    //     setRenderCron(false)
    // }, []);
    // const onShowProjectLog = React.useCallback(() => {
    //     setRenderProjectLog(true)
    //     setRenderProject(false)
    //     setRenderUser(false)
    //     setRenderProjectType(false)
    //     setRenderCron(false)
    // }, []);
    // const onShowProjectType = React.useCallback(() => {
    //     setRenderProjectType(true)
    //     setRenderProjectLog(false)
    //     setRenderProject(false)
    //     setRenderUser(false)
    //     setRenderCron(false)
    // }, []);
    // const onShowCron = React.useCallback(() => {
    //     setRenderCron(true)
    //     setRenderProjectType(false)
    //     setRenderProjectLog(false)
    //     setRenderProject(false)
    //     setRenderUser(false)
    // }, []);

    const toggleAuth = () => {
        setIsAuthentiacted(!isAuthentiacted)
        localStorage.clear()
    }

    function handleSubmit(formData) {
        setIsSubmitted(true)
        AuthService.login(formData)
            .then(reponse => {
                    setIsSubmitted(false)
                    setAuth(reponse)
                    setIsAuthentiacted(true)
                    setErrorMessage('')
                }
            ).catch(error => {
            setIsSubmitted(false)
            setErrorMessage(error.response.data.message ? error.response.data.message : error.response.statusText)
        })
    }

    useEffect(() => {
        setAuth(
            localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : []
        )
    }, [])

    const authContext = {
        auth,
        isSubmitted,
        isAuthentiacted,
        toggleAuth,
        handleSubmit,
        errorMessage,
        setErrorMessage,
        // hasRenderUser,
        // hasRenderProject,
        // hasRenderProjectType,
        // hasRenderProjectLog,
        // hasRenderCron,
        // setRenderUser,
        // setRenderProject,
        // setRenderProjectLog,
        // setRenderProjectType,
        // setRenderCron
    }

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
