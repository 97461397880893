import React from 'react'

const PaginationPerPage = (props) => {
    const {currentPage, perPage, total, pageinate, onChangePerPage} = props
    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(total / perPage); i++) {
        pageNumbers.push(i)
    }
    //new style paging
    var current = currentPage, last = Math.ceil(total / perPage)
    var delta = 2, left = current - delta, right = current + delta + 1
    var l, range = [], rangeWithDots = []
    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
        }
    }
    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return (
        <>
            <nav>
                <ul className="unstyled list-pagination">
                    <li className="per-page">
                        <select className="btn btn-light m-1"
                                onChange={onChangePerPage.bind()}
                                value={perPage}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </li>
                    {rangeWithDots.map((num, i) => (
                        <li key={i} className="hidden-mobile">
                            <a onClick={() => pageinate(num)}
                               className={num === currentPage
                                   ? "btn btn-light m-1"
                                   : "np-btn m-1"}>{num}</a>
                        </li>
                    ))}

                    <li className="show-mobile hidden-tablet">
                        <a onClick={() => pageinate(currentPage - 1 > 0 ? currentPage - 1 : currentPage)}
                           className="btn btn-light m-1">
                            <i className="bi bi-chevron-left pagination-left"></i>
                        </a>
                    </li>
                    <li className="show-mobile hidden-tablet">
                        <a onClick={() => pageinate(currentPage + 1 > last ? currentPage : currentPage + 1)}
                           className="btn btn-light m-1">
                            <i className="bi bi-chevron-right pagination-right"/>
                        </a>
                    </li>
                </ul>
            </nav>
            {total ? (
                <div>
                    {/* <div style={{ backgroundColor: "#D9EEE1" }}> */}
                    <a className="btn btn-light mt-1 text-warning show-mobile hidden-tablet">
                        {(currentPage - 1) * perPage === 0 ? 1 : (currentPage - 1) * perPage} - {currentPage * perPage > total ? total : currentPage * perPage} in {total}
                    </a>
                </div>
            ) : ''}
        </>
    )
}

export default PaginationPerPage
