import React, {useContext, useDeferredValue} from 'react';
import ProjectContext from '../../../context/ProjectContext';
import PaginationPerPage from '../../layouts/PaginationPerPage';
import Loading from '../../layouts/Loading';
import Global from '../../../libs/Global';
import Project from './Project';
import Util from '../../../libs/Util';
import ProjectLeader from './ProjectLeader';
import ProjectClient from './ProjectClient';

const Projects = (props) => {
    const {auth} = props
    const {
        total,
        projects,
        isLoading, deleteProject,
        onChangePerPage, perPage,
        pageinate, page,
        onSearch, search,
        openForm,
        editProjectFnc,
        projectTypes,
        tz,
        assignLeader,
        reAssignLeader,

        assignClient,
        reAssignClient,
        // exportExcel
    } = useContext(ProjectContext)

    const projectList = useDeferredValue(projects)
    const typeList = useDeferredValue(projectTypes)
    const handleDelete = (id) => {
        if (window.confirm(Global.deleteMessage())) {
            deleteProject(id);
        }
    }

    // const handlerExportExcel = () => {
    //     if (window.confirm(Global.exportExcelMessage())) {
    //         exportExcel()
    //     }
    // }

    let projectType, className, title, link, rowColor, rowBgColor = ''
    let worktimeLeft, worktimeLeftPercent, totalWorktime = 0
    let startDate, endDate = ''

    return isLoading ? (
        <Loading className="w-25"/>
    ) : (
        <main>
            <div className="table-head p-1">
                <h3>Projects</h3>
                <form className="float-end" action="#">
                    <input type="text" placeholder={Global.placeholderSearch()}
                           className="sr-input"
                           value={search}
                           onChange={e => onSearch(e)}/>
                    <button type="button" className="btn sr-btn" onClick={onSearch}>
                        <i className="bi bi-search"></i>
                    </button>
                </form>
            </div>

            <PaginationPerPage
                perPage={perPage}
                total={total}
                pageinate={pageinate}
                currentPage={page}
                onChangePerPage={onChangePerPage}
            />

            <table className="table table-striped data-table table-hover table-body p-2">
                <thead className="table-success">
                <tr>
                    <th scope="col" className="hidden-mobile">#</th>
                    <th scope="col" className="hidden-mobile">ID</th>
                    <th scope="col" className="hidden-mobile">Project name</th>
                    <th scope="col" className="hidden-mobile">Type</th>
                    <th scope="col" className="hidden-mobile">Start date</th>
                    <th scope="col" className="hidden-mobile">Due date</th>
                    <th scope="col" className="hidden-mobile">Init Hours</th>
                    <th scope="col" className="hidden-mobile" colSpan={2}><i
                        className="bi bi-hourglass-split"></i> Workhours left
                    </th>
                    <th scope="col" className="hidden-mobile"><i className="bi bi-hourglass-split"></i> Overtime</th>
                    <th scope="col" className="hidden-mobile"><i className="bi bi-hourglass-bottom"></i> Total hours
                    </th>
                    <th scope="col" className="hidden-mobile"><i className="bi bi-radioactive"></i> Status</th>
                    <th scope="col" className="hidden-mobile">Leader</th>
                    <th scope="col" className="hidden-mobile">Client</th>
                    <th scope="col" className="hidden-mobile">Link</th>
                    <th scope="col" className="text-end hidden-mobile" style={{width: "170px"}}>
                        <a className="download btn btn-primary"
                           href={process.env.REACT_APP_API_ENDPOINT + "/export-project?tz=" + tz + "&token=" + auth.authorisation.token}
                           download="ss" target="_blank" rel="noreferrer">
                            <i className="bi bi-download"></i> Excel
                        </a>
                        {" "}
                        <button className="btn btn-success" onClick={openForm}>
                            <i className="bi bi-plus-lg"></i></button>
                    </th>

                    <th scope="col" className="show-mobile hidden-tablet">Projects manager</th>
                    <th scope="col" className="text-end show-mobile hidden-tablet">
                        <a className="download btn btn-primary"
                           href={process.env.REACT_APP_API_ENDPOINT + "/export-project?tz=" + tz + "&token=" + auth.authorisation.token}
                           download="ss" target="_blank" rel="noreferrer">
                            <i className="bi bi-download"></i> Excel
                        </a>
                        {" "}
                        <button className="btn btn-success" onClick={openForm}>
                            <i className="bi bi-plus-lg"></i></button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {projectList && projectList.length ? (
                    projectList.map((project, index) => (
                        <tr key={index + 1}>
                            <th scope="row" className="hidden-mobile">
                                {index + 1}
                                <div style={{display: "none"}}>
                                    {projectType = typeList && typeList.find(x => x.id == project.type_id)
                                        ? typeList.find(x => x.id == project.type_id).name
                                        : ''}


                                    {title = (startDate = project.start_date)
                                        + ' ~ '
                                        + (endDate = project.end_date)}

                                    {worktimeLeft = project.status == Global.projectStatusClosed(false)
                                        ? Util.calWorktimeLeft(startDate, endDate)
                                        : project.start_date ? Util.worktimeLeft(startDate) : 0}

                                    {totalWorktime = project.start_date && project.end_date ? Util.calWorktimeLeft(startDate, endDate) : 0}

                                    {worktimeLeftPercent =
                                        project.status == Global.projectStatusOpen(false) || project.status == Global.projectStatusClosed(false)
                                            ? 0
                                            : project.start_date && project.end_date
                                                ? Util.worktimeLeftPercent(
                                                    startDate,
                                                    endDate
                                                )
                                                : 0}
                                    {rowColor = worktimeLeftPercent >= 90 && worktimeLeftPercent < 100 ? 'btn btn-danger' : 'btn btn-warning'}
                                    {rowBgColor = worktimeLeftPercent >= 90 && worktimeLeftPercent < 100 ? 'bg-danger' : 'bg-warning'}

                                    {className = project.status == Global.projectStatusOpen(false)
                                        ? 'btn btn-light'
                                        : project.status == Global.projectStatusInProgress(false)
                                            ? rowColor //'btn btn-warning'
                                            : 'btn btn-secondary'}
                                </div>
                            </th>
                            <td className="hidden-mobile">{project.id}</td>
                            <td className="hidden-mobile">{project.title}</td>
                            <td className="hidden-mobile">
                                {projectType}
                            </td>
                            <td className="hidden-mobile">
                                {project.start_date ? project.start_date : '-'}
                            </td>
                            <td className="hidden-mobile">
                                {project.end_date ? project.end_date : '-'}
                            </td>
                            <td className="hidden-mobile">
                                {project.init_hours ? project.init_hours + 'H' : 0}
                            </td>
                            <td className="hidden-mobile" style={{width: "120px"}}>
                                <div
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    className={className + ' w-100'}
                                    title={title}>
                                    {project.workHoursLeft}
                                    {Util.getMinutes()}
                                </div>
                            </td>
                            <td className="hidden-mobile" style={{width: "100px"}}>
                                {worktimeLeftPercent
                                    ? worktimeLeftPercent < 100 ? (
                                        <div style={{height: "38px"}}
                                             className="progress bg-secondary w-100">
                                            <div
                                                className={"progress-bar progress-bar-striped progress-bar-animated text-dark " + rowBgColor}
                                                role="progressbar"
                                                aria-valuenow={worktimeLeftPercent}
                                                aria-valuemin="0"
                                                aria-valuemax="100">
                                                {worktimeLeftPercent}%
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{height: "38px"}}
                                             className="progress w-100">
                                            <div
                                                className="progress-bar progress-bar-striped progress-bar-animated w-100 bg-warning"
                                                role="progressbar"
                                                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                100%
                                            </div>
                                        </div>
                                    )
                                    : Global.isOpenProject(project.status)
                                        ? (
                                            <div
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                className={className + ' w-100'}
                                                title={title}>
                                                {worktimeLeftPercent}
                                            </div>
                                        )
                                        : (
                                            <div style={{height: "38px"}}
                                                 className="progress w-100">
                                                <div
                                                    className="progress-bar progress-bar-striped progress-bar-animated w-100 bg-secondary"
                                                    role="progressbar"
                                                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                    100%
                                                </div>
                                            </div>
                                        )
                                }
                            </td>
                            <td className="hidden-mobile" style={{width: "120px"}}>
                                {project.workOvertime}
                            </td>
                            <td className="hidden-mobile" style={{width: "150px"}}>
                                <div className={className + ' w-100'}>
                                    {project.totalHours}
                                </div>
                            </td>
                            <td className="hidden-mobile">
                                <div className={className + ' w-100'}>
                                    {/* {Util.projectStatus(project.status)} */}
                                    {project.projectStatus}
                                </div>
                            </td>

                            {/* assign PM */}
                            <td className="hidden-mobile">
                                {project.fullName
                                    ? (<a
                                        onClick={() => reAssignLeader(project)}>
                                            <span className="d-inline-block"
                                                  tabIndex="0"
                                                  title={project.fullName}
                                                  data-bs-toggle="tooltip">
                                                <i className="bi bi-person-circle fs-5 link-primary"></i> {project.fullName}</span></a>)
                                    : (
                                        <a className={'btn btn-primary'}
                                           onClick={() => assignLeader(project)}>
                                            <span>Assignee</span>
                                        </a>
                                    )}
                            </td>

                            {/* assign Client */}
                            <td className="hidden-mobile">
                                {project.clientName
                                    ? (<a
                                        onClick={() => reAssignClient(project)}>
                                            <span className="d-inline-block"
                                                  tabIndex="0"
                                                  title={project.clientName}
                                                  data-bs-toggle="tooltip">
                                                <i className="bi bi-person-circle fs-5 link-primary"></i> {project.clientName}</span></a>)
                                    : (
                                        <a className={'btn btn-primary'}
                                           onClick={() => assignClient(project)}>
                                            <span>Add</span>
                                        </a>
                                    )}
                            </td>

                            <td className="hidden-mobile">
                                {link = project.link
                                    ? (<a className="btn btn-primary"
                                          href={project.link}
                                          title={project.link}
                                          rel="noopener noreferrer"
                                          target="_blank">
                                        Link
                                    </a>)
                                    : ''
                                }
                            </td>


                            <td scope="row" className="show-mobile hidden-tablet">
                                <p><b>#</b>{index + 1}. <b className="text-primary">ID: </b>{project.id}</p>
                                <p><b className="text-primary">{project.title}</b></p>
                                <p><b>Type: </b>{projectType}</p>
                                <p><b className="text-success">Period: </b>{title}</p>
                                <p><b>Total Hours: </b>{project.totalHours}</p>
                                <p><b>Init Hours: </b>{project.init_hours ? project.init_hours + 'H' : 0}</p>
                                <p className={className + ' w-100'}>
                                    {project.workHoursLeft}
                                    {Util.getMinutes()}
                                    {"   "}
                                    {Util.projectStatus(project.status)}
                                </p>
                                {worktimeLeftPercent
                                    ? worktimeLeftPercent < 100 ? (
                                        <div style={{height: "38px"}}
                                             className="progress bg-secondary w-100">
                                            <div
                                                className={"progress-bar progress-bar-striped progress-bar-animated text-dark " + rowBgColor}
                                                role="progressbar"
                                                aria-valuenow={worktimeLeftPercent}
                                                aria-valuemin="0"
                                                aria-valuemax="100">
                                                {worktimeLeftPercent}%
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{height: "38px"}}
                                             className="progress w-100">
                                            <div
                                                className="progress-bar progress-bar-striped progress-bar-animated w-100 bg-warning"
                                                role="progressbar"
                                                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                100%
                                            </div>
                                        </div>
                                    )
                                    : Global.isOpenProject(project.status)
                                        ? (
                                            <div
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                className={className + ' w-100'}
                                                title={title}>
                                                {worktimeLeftPercent}
                                            </div>
                                        )
                                        : (
                                            <div style={{height: "38px"}}
                                                 className="progress w-100">
                                                <div
                                                    className="progress-bar progress-bar-striped progress-bar-animated w-100 bg-secondary"
                                                    role="progressbar"
                                                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                    100%
                                                </div>
                                            </div>
                                        )
                                }
                                <p><b>Overtime: </b>{project.overtime ? project.overtime + 'H' : 0}</p>

                                <div className="mt-1">
                                    {project.fullName
                                        ? (<a
                                            onClick={() => reAssignLeader(project)}>
                                                <span className="d-inline-block"
                                                      tabIndex="0"
                                                      title={project.fullName}
                                                      data-bs-toggle="tooltip">
                                                    <i className="bi bi-person-circle fs-5 link-primary"></i> {project.fullName}</span></a>)
                                        : (
                                            <a className={'btn btn-primary'}
                                               onClick={() => assignLeader(project)}>
                                                <span>Assignee</span>
                                            </a>
                                        )}
                                </div>
                                <div className="mt-1">
                                    {project.clientName
                                        ? (<a
                                            onClick={() => reAssignClient(project)}>
                                            <span className="d-inline-block"
                                                  tabIndex="0"
                                                  title={project.clientName}
                                                  data-bs-toggle="tooltip">
                                                <i className="bi bi-person-circle fs-5 link-primary"></i> {project.clientName}</span></a>)
                                        : (
                                            <a className={'btn btn-primary'}
                                               onClick={() => assignClient(project)}>
                                                <span>Add</span>
                                            </a>
                                        )}

                                </div>
                            </td>

                            <td className="text-end">
                                <div className="show-mobile hidden-tablet">{link}</div>
                                {" "}
                                <a type="button" className="btn btn-warning mt-1"
                                   onClick={() => editProjectFnc(project)}>
                                    <i className="bi bi-pencil-square"></i>
                                </a>
                                {" "}
                                <a type="button" className={Global.isInProgressProject(project.status)
                                    ? "btn btn-secondary mt-1" : "btn btn-danger mt-1"}
                                   onClick={() => {
                                       Global.isInProgressProject(project.status)
                                           ? Global.warning('This project work in progress, you can not delete!')
                                           : handleDelete(project.id)
                                   }}>
                                    <i className="bi bi-x-lg"></i>
                                </a>

                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="hidden-mobile" colSpan={17}>There are no projects.</td>
                        <td className="show-mobile hidden-tablet" colSpan={2}>There are no projects.</td>
                    </tr>
                )}
                </tbody>
            </table>
            <Project/>
            <ProjectLeader/>
            <ProjectClient/>
        </main>
    )
}

export default Projects
