import React, {useContext} from 'react'
import AuthContext from '../../../context/AuthContext'
import {ProjectTypeProvider} from '../../../context/ProjectTypeProvider'
import Global from '../../../libs/Global'
import ProjectTypes from './ProjectTypes'

const ProjectTypeManager = () => {
    const {auth} = useContext(AuthContext);
    return auth && auth.user && Global.isSuperAdmin(auth.user.type) ? (
        <div className="container mb-5">
            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">
                    <ProjectTypeProvider>
                        <ProjectTypes/>
                    </ProjectTypeProvider>
                </div>
            </div>
        </div>
    ) : Global.reLogin()
}

export default ProjectTypeManager
