import React, {useContext, useDeferredValue} from 'react';
import SettingContext from '../../../context/SettingContext';
import PaginationPerPage from '../../layouts/PaginationPerPage';
import Loading from '../../layouts/Loading';
import Global from '../../../libs/Global';
import Setting from './Setting';
import {FastField} from 'formik';
import Util from '../../../libs/Util';

const Settings = () => {
    const {
        total,
        Settings,
        isLoading, deleteSetting,
        onChangePerPage, perPage,
        pageinate, page,
        onSearch, search,
        openForm,
        editSettingFnc,
        changeStatusItem,
        Countries,
    } = useContext(SettingContext)
    const DATE_OPTIONS = {year: 'numeric', month: 'short', day: 'numeric', weekday: 'long'}
    const SettingList = useDeferredValue(Settings)
    const CountryList = useDeferredValue(Countries)
    const handleDelete = (id) => {
        if (window.confirm(Global.deleteMessage())) {
            deleteSetting(id);
        }
    }
    const handleStatus = (id, status) => {
        if (window.confirm(Global.changeStatusMessage())) {
            changeStatusItem(id, Global.isEnableSetting(status));
        }
    }

    return isLoading ? (
        <Loading className="w-25"/>
    ) : (
        <main>
            <div className="table-head p-1">
                <h3>
                    <div className="hidden-mobile">Holiday Setting Manager</div>
                    <div className="show-mobile hidden-tablet">Holiday</div>
                </h3>
                <form className="float-end" action="#">
                    <input type="text" placeholder={Global.placeholderSearch()}
                           className="sr-input"
                           value={search}
                           onChange={e => onSearch(e)}/>
                    <button type="button" className="btn sr-btn" onClick={onSearch}>
                        <i className="bi bi-search"></i>
                    </button>
                </form>

            </div>
            <PaginationPerPage
                perPage={perPage}
                total={total}
                pageinate={pageinate}
                currentPage={page}
                onChangePerPage={onChangePerPage}
            />
            <table className="table table-striped data-table table-hover table-body p-2">
                <thead className="table-success">
                <tr>
                    <th scope="col" className="hidden-mobile">#</th>
                    <th scope="col" className="hidden-mobile">Date</th>
                    <th scope="col" className="hidden-mobile">Name</th>
                    <th scope="col" className="hidden-mobile">Information</th>
                    <th scope="col" className="hidden-mobile">Country</th>
                    <th scope="col" className="hidden-mobile text-end" style={{width: "120px"}}>
                        <button className="btn btn-success" onClick={openForm}><i className="bi bi-plus-lg"></i>
                        </button>
                    </th>
                    <th scope="col" className="show-mobile hidden-tablet">Holiday Setting Information</th>
                    <th scope="col" className="text-end show-mobile hidden-tablet">
                        <button className="btn btn-success" onClick={openForm}><i className="bi bi-plus-lg"></i>
                        </button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {SettingList && SettingList.length ? (
                    SettingList.map((Setting, index) => (

                        <tr key={Setting.id}>
                            <th scope="row" className="hidden-mobile">{index + 1}</th>
                            <td className="hidden-mobile">{(new Date(Setting.holiday)).toLocaleDateString('en-US', DATE_OPTIONS)}</td>
                            <td className="hidden-mobile">{Setting.name}</td>
                            <td className="hidden-mobile">{Setting.value}</td>
                            <td className="hidden-mobile">
                                {CountryList && CountryList.find(x => x.id == Setting.country_id)
                                    ? CountryList.find(x => x.id == Setting.country_id).name
                                    : ''}
                            </td>
                            <th scope="row" className="show-mobile hidden-tablet">
                                <p><b>#</b>{index + 1}. <b className="text-success">{/* Setting.id */}</b>
                                    <span
                                        className="btn btn-warning">{(new Date(Setting.holiday)).toLocaleDateString('en-US', DATE_OPTIONS)}</span>
                                    <span className="btn btn-light">{Setting.name}</span>
                                    <span className="btn btn-light">
                                            {CountryList && CountryList.find(x => x.id == Setting.country_id)
                                                ? CountryList.find(x => x.id == Setting.country_id).name
                                                : ''}</span>
                                    {Setting.value}
                                    {" "}
                                </p>
                            </th>
                            <td className="text-end">
                                <button type="button" className="btn btn-warning"
                                        onClick={() => editSettingFnc(Setting)}>
                                    <i className="bi bi-pencil-square"></i>
                                </button>
                                {" "}
                                <div className="show-mobile hidden-tablet"><br/></div>
                                <button type="button" className="btn btn-danger"
                                        onClick={() => handleDelete(Setting.id)}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="hidden-mobile" colSpan={6}>There are no Settings.</td>
                        <td className="show-mobile hidden-tablet" colSpan={2}>There are no Settings.</td>
                    </tr>
                )}
                </tbody>
            </table>
            <Setting/>
        </main>
    )
}

export default Settings
