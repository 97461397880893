import React, {useContext} from 'react'
import AuthContext from '../../../context/AuthContext'
import {ProjectProvider} from '../../../context/ProjectProvider'
import Global from '../../../libs/Global'
import Projects from './Projects'

const ProjectManager = () => {
    const {auth} = useContext(AuthContext);

    return auth && auth.user && Global.isSuperAdmin(auth.user.type)
        ? (
            <div className="container-fluid mb-5">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                        <ProjectProvider>
                            <Projects auth={auth}/>
                        </ProjectProvider>
                    </div>
                </div>
            </div>
        )
        : Global.reLogin()
}

export default ProjectManager
