import React, {useContext} from 'react'
import AuthContext from '../../../context/AuthContext'
import {ProjectLogProvider} from '../../../context/ProjectLogProvider'
import Global from '../../../libs/Global'
import ProjectLog from './ProjectLog'

const ProjectLogManager = () => {
    const {auth} = useContext(AuthContext);

    return auth && auth.user && Global.isSuperAdmin(auth.user.type)
        ? (
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                        <ProjectLogProvider>
                            <ProjectLog auth={auth}/>
                        </ProjectLogProvider>
                    </div>
                </div>
            </div>

        )
        : Global.reLogin()
}

export default ProjectLogManager
