import React from 'react'

const Homepage = (props) => {
    const {auth} = props
    return (
        <div className="text-center text-light p-3" style={{backgroundColor: "#282A35"}}>
            Hi {auth.user.name}, <br/>
            You were login successfully!
        </div>
    )
}

export default Homepage
