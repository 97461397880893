import React, {useEffect, useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import Loading from '../../layouts/Loading';
import SettingContext from '../../../context/SettingContext';
import useWindowDimensions from '../../../libs/useWindowDimensions'
import Global from '../../../libs/Global';
import DateTimePicker from 'react-datetime-picker';
import {DefaultEditor, BtnBold, BtnItalic, Editor, Toolbar} from 'react-simple-wysiwyg';
import Util from '../../../libs/Util';

const Setting = (props) => {
    const {
        isOpen, closeModal,
        SettingEdit,
        errorMessage,
        addSetting,
        updateSetting,
        tz,
        Countries
    } = useContext(SettingContext)
    const [name, setName] = useState('')
    const [value, setValue] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [fullscreen, setFullscreen] = useState('')
    const {modelSize} = useWindowDimensions()
    const [holidayDate, setHolidayDate] = useState(new Date())
    const [countryId, setCountryId] = useState(0);

    // Customize your editor with css rules
    var editorStyle = {
        overflow: 'auto',
        height: 150,
        maxHeight: 150
    }

    const submitFormHandler = (Setting) => {
        if (SettingEdit.isEdit) {
            updateSetting(SettingEdit.Setting.id, Setting);
        } else {
            addSetting(Setting);
        }
    }

    // Generate JSX code for error message
    const renderErrorMessage = (message, className = "text-danger") => (
        <div className={className}>
            {message}
        </div>
    );

    useEffect(() => {
        if (SettingEdit.isEdit) {
            setName(SettingEdit.Setting.name)
            setValue(SettingEdit.Setting.value ? SettingEdit.Setting.value : '')
            setHolidayDate(new Date(Util.convertUTC(SettingEdit.Setting.holiday, tz)))
            setCountryId(SettingEdit.Setting.country_id)
        } else {
            setName('')
            setValue('')
            setHolidayDate(new Date())
            setCountryId(0)
        }
    }, [SettingEdit]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            value: value,
            holiday: holidayDate,
            country_id: countryId
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required"),
            value: Yup.string(),
            holiday: Yup.string().nullable(true),
            country_id: Yup.number()
                .min(1, 'Required'),
        }),
        onSubmit: values => {
            const param = JSON.stringify(values, null, 2);
            submitFormHandler(JSON.parse(param))
            setIsSubmitted(true);
        }
    });

    const SettingForm = () => (
        <Modal dialogClassName={modelSize + " modal-dialog-centered modal-dialog-scrollable " + fullscreen}
               show={isOpen} onHide={closeModal} animation={true}>
            <Modal.Header closeButton className="manager-head bg-light">
                <h3 className="card-title w-100">
                    {SettingEdit.isEdit ? "Update Holiday Setting" : "Add New Holiday Setting"}
                </h3>
                <button className="float-end me-2 btn" onClick={() => {
                    setFullscreen(fullscreen === 'modal-fullscreen' ? '' : 'modal-fullscreen')
                }}>
                    <i className={fullscreen === 'modal-fullscreen' ? "bi-arrows-angle-contract" : "bi bi-arrows-fullscreen"}></i>
                </button>
            </Modal.Header>
            <Modal.Body style={{height: "90%"}}>
                <div className="d-flex justify-content-center h-100 w-100">
                    <div className="container-fluid card register mt-3">
                        <div className="card-body">
                            {isSubmitted ? (
                                <Loading className="w-25"/>
                            ) : ''}
                            {renderErrorMessage(errorMessage)}
                            <form onSubmit={formik.handleSubmit}>
                                <label className="label-control" htmlFor="holiday">
                                    <i className="bi bi-alarm">{" "}</i>Holiday Date
                                </label>
                                <div className="form-floating mb-3">
                                    <DateTimePicker name="holiday"
                                                    value={holidayDate}
                                                    onChange={setHolidayDate}
                                                    format="y-MM-dd"
                                    />

                                    {formik.errors.holiday && formik.touched.holiday &&
                                        renderErrorMessage(formik.errors.holiday)
                                    }
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Holiday Name" name="name"
                                           id="name"
                                           value={formik.values.name}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.name && formik.touched.name &&
                                        renderErrorMessage(formik.errors.name)
                                    }
                                    <label className="control-label" htmlFor="name">Holiday Name</label>
                                </div>

                                <div className="form-group mb-3">
                                    <label className="control-label" htmlFor="value">Holiday Information</label>
                                    <DefaultEditor
                                        id="value"
                                        name="value"
                                        value={formik.values.value}
                                        onChange={formik.handleChange}
                                        style={editorStyle}
                                    >
                                        <Toolbar>
                                            <BtnBold/>
                                            <BtnItalic/>
                                            <Editor/>
                                        </Toolbar>
                                    </DefaultEditor>
                                    {formik.errors.value && formik.touched.value &&
                                        renderErrorMessage(formik.errors.value)
                                    }

                                </div>

                                <div className="form-floating mb-3">
                                    <select className="form-select" id="country_id" name="country_id"
                                            onChange={e => {
                                                formik.handleChange(e);
                                                setCountryId(e.target.value)
                                            }}
                                            value={formik.values.country_id}>
                                        <option value="0">Select Country</option>
                                        {Countries ? Countries.map((item, index) => (
                                            <option key={index} value={item.id} label={item.name}/>
                                        )) : ''}
                                    </select>
                                    {formik.errors.country_id && formik.touched.country_id &&
                                        renderErrorMessage(formik.errors.country_id)}
                                    <label className="control-label" htmlFor="country_id">
                                        <i className="bi-clipboard-check">{" "}</i>Country</label>
                                </div>

                                <div className="form-group text-end mt-2">
                                    <button type="submit" className="btn btn-success">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );

    return SettingForm()

}

export default Setting
