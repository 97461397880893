import React, {useEffect, useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import Loading from '../../layouts/Loading';
import UserContext from '../../../context/UserContext';
// import TimezoneSelect from "react-timezone-select";
import useWindowDimensions from '../../../libs/useWindowDimensions'
import Global from '../../../libs/Global';

const User = (props) => {
    const {
        isOpen, closeModal,
        userEdit,
        // tz, setTz, datetime, allTimezones,
        errorMessage,
        addUser,
        updateUser
    } = useContext(UserContext)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [type, setType] = useState(0)
    const [isProjectManager, setIsProjectManager] = useState(0)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [fullscreen, setFullscreen] = useState('')
    const {modelSize} = useWindowDimensions()

    const submitFormHandler = (user) => {
        if (userEdit.isEdit) {
            updateUser(userEdit.user.id, user);
        } else {
            addUser(user);
        }
    }

    // Generate JSX code for error message
    const renderErrorMessage = (message, className = "text-danger") => (
        <div className={className}>
            {message}
        </div>
    );

    useEffect(() => {
        if (userEdit.isEdit) {
            setName(userEdit.user.name)
            setEmail(userEdit.user.email)
            setType(userEdit.user.type)
            setIsProjectManager(userEdit.user.is_leader)
        } else {
            setName('')
            setEmail('')
            setType(0)
            setIsProjectManager(0)
        }
    }, [userEdit]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            email: email,
            password: '',
            password_confirmation: '',
            type: type,
            is_leader: isProjectManager
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required"),
            email: Yup.string()
                .email("Invalid email format")
                .min(2, "Mininum 2 characters")
                .max(255, "Maximum 255 characters")
                .required("Required"),
            password: Yup.string()
                .min(6, "Minimum 6 characters")
                .required("Required"),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required("Required!"),
            type: Yup.string().required("Required!"),
        }),
        onSubmit: values => {
            const param = JSON.stringify(values, null, 2);
            submitFormHandler(JSON.parse(param))
            setIsSubmitted(true);
        }
    });

    const userForm = () => (
        <Modal dialogClassName={modelSize + " modal-dialog-centered modal-dialog-scrollable " + fullscreen}
               show={isOpen} onHide={closeModal} animation={true}>
            <Modal.Header closeButton className="manager-head bg-light">
                <h3 className="card-title w-100">
                    {userEdit.isEdit ? "Update User" : "Add New User"}
                </h3>
                <button className="float-end me-2 btn" onClick={() => {
                    setFullscreen(fullscreen === 'modal-fullscreen' ? '' : 'modal-fullscreen')
                }}>
                    <i className={fullscreen === 'modal-fullscreen' ? "bi-arrows-angle-contract" : "bi bi-arrows-fullscreen"}></i>
                </button>
            </Modal.Header>
            <Modal.Body style={{height: "90%"}}>
                <div className="d-flex justify-content-center h-100 w-100">
                    <div className="container-fluid card register mt-3">
                        <div className="card-body">
                            {isSubmitted ? (
                                <Loading className="w-25"/>
                            ) : ''}
                            {renderErrorMessage(errorMessage)}
                            {/* {renderErrorMessage(successMessages, "text-success")} */}
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Full Name" name="name"
                                           value={formik.values.name}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.name && formik.touched.name &&
                                        renderErrorMessage(formik.errors.name)
                                    }
                                    <label className="control-label" htmlFor="name">
                                        <i className="bi bi-person">{" "}</i>Full Name

                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    {userEdit.isEdit
                                        ? <input type="text" className="form-control" placeholder="Email" name="email"
                                                 value={formik.values.email}
                                                 onChange={formik.handleChange}
                                                 readOnly={true}/>
                                        : <input type="text" className="form-control" placeholder="Email" name="email"
                                                 value={formik.values.email}
                                                 onChange={formik.handleChange}/>
                                    }
                                    {formik.errors.email && formik.touched.email &&
                                        renderErrorMessage(formik.errors.email)}

                                    <label className="control-label" htmlFor="email">
                                        <i className="bi bi-envelope">{" "}</i> Email
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="password" className="form-control" placeholder="Password"
                                           name="password"
                                           value={formik.values.password}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.password && formik.touched.password &&
                                        renderErrorMessage(formik.errors.password)}
                                    <label className="control-label" htmlFor="password">
                                        <i className="bi bi-lock"></i> Password
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="password" className="form-control" placeholder="Re-Password"
                                           name="password_confirmation"
                                           value={formik.values.password_confirmation}
                                           onChange={formik.handleChange}
                                    />
                                    {formik.errors.password_confirmation && formik.touched.password_confirmation &&
                                        renderErrorMessage(formik.errors.password_confirmation)}

                                    <label className="control-label" htmlFor="password_confirmation">
                                        <i className="bi bi-lock"></i> Password Confirmation
                                    </label>
                                </div>

                                {/* <div className="input-group form-group mb-3">
                                    <label className="form-control w-25" htmlFor="timezone"><i className="bi bi-globe2">{" "}</i>Timezone</label>
                                    <TimezoneSelect
                                        className="form-control w-75"
                                        name="timezone"
                                        value={tz}
                                        onChange={formik.values.timezone}
                                        timezones={{
                                            ...allTimezones,
                                            "America/Lima": "Pittsburgh",
                                            "Europe/Berlin": "Frankfurt"
                                        }}
                                    />
                                </div> */}

                                <div className="form-floating mb-3">
                                    <select className="form-select" id="type" name="type"
                                            onChange={formik.handleChange}
                                            value={formik.values.type}
                                    >
                                        <option value={0} label={Global.userRoleUser()}>{Global.userRoleUser()}</option>
                                        <option value={1}
                                                label={Global.userRoleSuperAdmin()}>{Global.userRoleSuperAdmin()}</option>
                                    </select>
                                    {formik.errors.type && formik.touched.type &&
                                        renderErrorMessage(formik.errors.type)}
                                    <label className="control-label" htmlFor="type">
                                        <i className="bi bi-incognito">{" "}</i>Role
                                    </label>
                                </div>

                                <div className="form-floating mb-3">
                                    <select className="form-select" id="is_leader" name="is_leader"
                                            onChange={formik.handleChange}
                                            value={formik.values.is_leader}
                                    >
                                        <option value={0} label={Global.userMember()}>{Global.userMember()}</option>
                                        <option value={1} label={Global.userLeader()}>{Global.userLeader()}</option>
                                    </select>
                                    {formik.errors.type && formik.touched.type &&
                                        renderErrorMessage(formik.errors.type)}
                                    <label className="control-label" htmlFor="type">
                                        {/* <i className="bi bi-incognito">{" "}</i> */}
                                        Is Leader
                                    </label>
                                </div>


                                {/* <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="is_leader"
                                        name="is_leader"
                                        onChange={formik.handleChange}
                                        checked={formik.values.is_leader ? true : false}
                                    />
                                    <label className="form-check-label" htmlFor="is_leader">Is Leader</label>
                                </div> */}

                                <div className="form-group text-end mt-2">
                                    <button type="submit" className="btn btn-success">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
    );

    return userForm()

}

export default User
