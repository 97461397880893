import React, {useContext, useDeferredValue} from 'react';
import ProjectTypeContext from '../../../context/ProjectTypeContext';
import PaginationPerPage from '../../layouts/PaginationPerPage';
import Loading from '../../layouts/Loading';
import Global from '../../../libs/Global';
import ProjectType from './ProjectType';
import {FastField} from 'formik';

const ProjectTypes = () => {
    const {
        total,
        projecttypes,
        isLoading, deleteProjectType,
        onChangePerPage, perPage,
        pageinate, page,
        onSearch, search,
        openForm,
        editProjectTypeFnc,
        changeStatusItem
    } = useContext(ProjectTypeContext)

    const projecttypeList = useDeferredValue(projecttypes);

    const handleDelete = (id) => {
        if (window.confirm(Global.deleteMessage())) {
            deleteProjectType(id);
        }
    }

    const handleStatus = (id, status) => {
        if (window.confirm(Global.changeStatusMessage())) {
            changeStatusItem(id, Global.isEnableProjectType(status));
        }
    }

    return isLoading ? (
        <Loading className="w-25"/>
    ) : (
        <main>
            <div className="table-head p-1">
                <h3>
                    <div className="hidden-mobile">Project Type Manager</div>
                    <div className="show-mobile hidden-tablet">Types</div>
                </h3>
                <form className="float-end" action="#">
                    <input type="text" placeholder={Global.placeholderSearch()}
                           className="sr-input"
                           value={search}
                           onChange={e => onSearch(e)}/>
                    <button type="button" className="btn sr-btn" onClick={onSearch}>
                        <i className="bi bi-search"></i>
                    </button>
                </form>
            </div>
            <PaginationPerPage
                perPage={perPage}
                total={total}
                pageinate={pageinate}
                currentPage={page}
                onChangePerPage={onChangePerPage}
            />
            <table className="table table-striped data-table table-hover table-body p-2">
                <thead className="table-success">
                <tr>
                    <th scope="col" className="hidden-mobile">#</th>
                    <th scope="col" className="hidden-mobile">ID</th>
                    <th scope="col" className="hidden-mobile w-50">Project type</th>
                    <th scope="col" className="hidden-mobile" style={{width: "100px"}}>Order <i
                        className="bi bi-sort-numeric-down-alt"></i></th>
                    <th scope="col" className="hidden-mobile" style={{width: "100px"}}>
                        Status <i className="text-left bi bi-radioactive"></i>
                    </th>
                    <th scope="col" className="hidden-mobile text-end" style={{width: "120px"}}>
                        <button className="btn btn-success" onClick={openForm}><i className="bi bi-plus-lg"></i>
                        </button>
                    </th>
                    <th scope="col" className="show-mobile hidden-tablet">Project type list</th>
                    <th scope="col" className="text-end show-mobile hidden-tablet">
                        <button className="btn btn-success" onClick={openForm}><i className="bi bi-plus-lg"></i>
                        </button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {projecttypeList && projecttypeList.length ? (
                    projecttypeList.map((projecttype, index) => (
                        <tr key={projecttype.id}>
                            <th scope="row" className="hidden-mobile">{index + 1}</th>
                            <td className="hidden-mobile">{projecttype.id}</td>
                            <td className="hidden-mobile">{projecttype.name}</td>
                            <td className="hidden-mobile">{projecttype.orderby}</td>
                            <td className="hidden-mobile">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                           type="checkbox"
                                           id="flexSwitchCheckDefault"
                                           name="status"
                                           checked={Global.isEnableProjectType(projecttype.status) ? true : false}
                                           onChange={() => handleStatus(projecttype.id, projecttype.status)}
                                    /></div>
                            </td>
                            <th scope="row" className="show-mobile hidden-tablet">
                                <p><b>#</b>{index + 1}. <b className="text-success">ID: </b>{projecttype.id}
                                    {" "}
                                    <span className="btn btn-light">{projecttype.orderby}</span>
                                    {" "}
                                </p>
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                           type="checkbox"
                                           id="flexSwitchCheckDefault"
                                           name="status"
                                           checked={Global.isEnableProjectType(projecttype.status) ? true : false}
                                           onChange={() => handleStatus(projecttype.id, projecttype.status)}
                                    />
                                    <label className="form-check-label"
                                           htmlFor="flexSwitchCheckDefault">{projecttype.name}</label>
                                </div>
                            </th>
                            <td className="text-end">
                                <button type="button" className="btn btn-warning"
                                        onClick={() => editProjectTypeFnc(projecttype)}>
                                    <i className="bi bi-pencil-square"></i>
                                </button>
                                {/* {" "}
                                    <div className="show-mobile hidden-tablet"><br /></div>
                                    <button type="button" className="btn btn-danger"
                                        onClick={() => handleDelete(projecttype.id)}>
                                        <i className="bi bi-x-lg"></i>
                                    </button> */}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="hidden-mobile" colSpan={6}>There are no projecttypes.</td>
                        <td className="show-mobile hidden-tablet" colSpan={2}>There are no projecttypes.</td>
                    </tr>
                )}
                </tbody>
            </table>
            <ProjectType/>
        </main>
    )
}

export default ProjectTypes
