export default {
    logo: (url) => {
        return <img src={url} alt="B Solution" height="54px"/>
    },
    brandName: () => {
        return 'B Solution Services'
    },
    deleteMessage: () => {
        return 'Are you sure want to delete'
    },
    changeLeaderMessage: () => {
        return 'Are you sure want to change leader'
    },
    changeStatusMessage: () => {
        return 'Are you sure want to change status'
    },
    placeholderSearch: () => {
        return 'Search ...'
    },
    exportExcelMessage: () => {
        return 'Are you sure want to export Excel'
    },
    /**
     * no auth
     * @returns RSX
     */
    reLogin: () => {
        return (
            <div className="text-danger text-center mt-4">
                You do not have permission to access for this page!
                <br/>
                <a href={process.env.REACT_APP_PUBLIC_URL + "/"} className="btn btn-warning">Login</a> Try again!
            </div>
        )
    },
    /**
     * RSX show messages after action
     *
     * @param {string} message
     * @param {string} className
     * @returns
     */
    renderErrorMessage: (message, className = "text-danger") => {
        return (
            <div className={className}>
                {message}
            </div>
        )
    },
    warning: (message) => {
        return alert(message)
    },
    // Database difine
    // user role
    isSuperAdmin: (type) => {
        return type === 1 || type === '1'
    },
    userRole: (type) => {
        return type === 1 || type === '1' ? 'Super-admin' : 'User'
    },
    userRoleSuperAdmin: () => {
        return 'Super-admin'
    },
    userRoleUser: () => {
        return 'User'
    },

    //user leader
    isLeader: (isLeader) => {
        return isLeader === 1 || isLeader === '1'
    },
    userLeader: () => {
        return 'Yes'
    },
    userMember: () => {
        return 'No'
    },

    //project type status
    isEnableProjectType: (status) => {
        return status === 0 || status === '0'
    },
    projectTypeEnable: () => {
        return 'Enable'
    },
    projectTypeDisable: () => {
        return 'Disable'
    },

    /**
     * db project define
     */
    projectStatusOpen: (isString) => {
        return isString ? 'Open' : '0'
    },
    projectStatusInProgress: (isString) => {
        return isString ? 'In-Progress' : '1'
    },
    projectStatusClosed: (isString) => {
        return isString ? 'Closed' : '2'
    },
    isOpenProject: (status) => {
        return status === 0 || status === '0'
    },
    isInProgressProject: (status) => {
        return status === 1 || status === '1'
    },
    isClosedProject: (status) => {
        return status === 2 || status === '2'
    },
}
