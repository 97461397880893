import React, {useState, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment-timezone";
import {allTimezones} from "react-timezone-select";
import SettingContext from "./SettingContext";
import SettingService from "../libs/service/SettingService.js";

export const SettingProvider = ({children}) => {
    const navigate = useNavigate()
    // const [authMail, setAuthMail] = useState([]);
    const [Settings, setSettings] = useState([]);
    const [Countries, setCountries] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalCountry, setTotalCountry] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [SettingEdit, setSettingEdit] = useState({Setting: {}, isEdit: false});
    const [params, setParams] = useState({})
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false)

    const closeModal = () => {
        setIsOpen(false)
        setSettingEdit({Setting: {}, isEdit: false});
    }
    const openForm = () => {
        setSettingEdit({Setting: {}, isEdit: false});
        setIsOpen(true)
    }
    const editSettingFnc = (Setting) => {
        //pass item info to form edit
        setSettingEdit({Setting, isEdit: true});
        setIsOpen(true)
    };

    /***Paging ACTION***/
    const pageinate = (pageNumber) => {
        pageNumber = pageNumber ? pageNumber : 1
        setPage(pageNumber)
        const newParams = params
        newParams.page = pageNumber
        newParams.perPage = perPage
        newParams.search = search
        setParams(newParams)
    }

    // on change per page
    const onChangePerPage = (e) => {
        const newParams = params
        newParams.perPage = parseInt(e.target.value)
        newParams.page = 1
        setPerPage(parseInt(e.target.value))
    }

    const onSearch = (e) => {
        e.preventDefault()
        const newParams = params
        newParams.search = e.target.value
        newParams.page = 1
        newParams.perPage = perPage

        setSearch(e.target.value)
        setParams(newParams)
    }

    //timezone
    const [tz, setTz] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [datetime, setDatetime] = useState(moment());
    useMemo(() => {
        const tzValue = tz.value ?? tz; //??
        setDatetime(datetime.tz(tzValue));
    }, [tz, datetime]);


    //call API
    const fetchSettings = () => {
        const res = SettingService.getSettingList(params);
        res.then(
            (response) => {
                if (response.status === 401) {
                    localStorage.clear()
                    navigate('/')
                    navigate(0)
                }
                setSettings(response.data.data)
                setTotal(response.data.total)
                setIsLoading(false)
            }
            , (error) => {
                //Unauthenticated.
                if (error.response.status === 401) {
                    localStorage.clear()
                    navigate('/')
                    navigate(0)

                    //call api refresh token.
                    //SettingService.refreshToken()
                }
                setIsLoading(false)
            }
        )

        return Settings;
    };

    const reloadSettings = () => {
        const newParams = params
        newParams.search = search
        newParams.page = page
        newParams.perPage = perPage
        setParams(newParams)
        fetchSettings()
        setErrorMessage('')
    }

    // Setting form
    const addSetting = (newSetting) => {
        setIsLoading(true)
        const res = SettingService.createSetting(newSetting)
        res.then((response) => {
            reloadSettings()
            setIsLoading(false)
            //close popup
            setIsOpen(false)
            setSettingEdit({Setting: {}, isEdit: false});
        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setIsLoading(false)
        })
    };

    const updateSetting = (id, Setting) => {
        setIsLoading(true)
        const res = SettingService.updateSetting(id, Setting)
        res.then((response) => {
            reloadSettings()
            setIsLoading(false)
            //close popup
            setIsOpen(false)
            setSettingEdit({Setting: {}, isEdit: false});
        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setIsLoading(false)
        })
    };

    const deleteSetting = (id) => {
        setIsLoading(true)
        const res = SettingService.deleteSetting(id)
        res.then(response => {
            // console.log(response)
            setIsLoading(false)
            reloadSettings()
        }).catch(error => {
            // console.log(error)
            setIsLoading(false)
        })
    };

    const changeStatusItem = (id, status) => {
        setIsLoading(true)
        const res = SettingService.updateSetting(id, {status: status})
        res.then(response => {
            // console.log(response)
            setIsLoading(false)
            reloadSettings()
            // fetchSettings()
        }).catch(error => {
            // console.log(error)
            setIsLoading(false)
        })
    };

    //load more data: call API
    const loadCountries = () => {
        const res = SettingService.getCountries();
        res.then(response => {
            if (response.status === 401) {
                // console.log(response.data.message)
                localStorage.clear()
                navigate('/')
                navigate(0)
            }
            setTotalCountry(response.data.total)

            setCountries(response.data)
            setIsLoading(false)
        }).catch(error => {
            if (error.response.status === 401) { //Unauthenticated.
                setTimeout(() => {
                    setErrorMessage(error.response.data.message)
                    localStorage.clear()
                    navigate('/')
                    navigate(0)
                }, 200);
            }
            setIsLoading(false)
        })

        return Countries;
    };

    //Load List
    useEffect(() => {
        loadCountries()
        fetchSettings()

        return () => {
            loadCountries()
            fetchSettings()
        };
    }, [perPage, page, search])

    //ke ra ai thich sai cai nao thi sai
    const shareProvider = {
        total,
        Settings,
        isLoading,
        addSetting,
        updateSetting,
        deleteSetting,
        editSettingFnc,
        errorMessage,
        page,
        pageinate,
        perPage,
        onChangePerPage,
        search,
        onSearch,
        fetchSettings,
        isOpen,
        closeModal,
        openForm,
        SettingEdit,
        tz,
        setTz,
        datetime,
        allTimezones,
        changeStatusItem,
        Countries,
    }
    return (
        <SettingContext.Provider value={shareProvider}>
            {children}
        </SettingContext.Provider>
    );
};
