import React, {useContext, useDeferredValue} from 'react';
import UserContext from '../../../context/UserContext';
import PaginationPerPage from '../../layouts/PaginationPerPage';
import Loading from '../../layouts/Loading';
import Global from '../../../libs/Global';
import User from './User';

const Users = (props) => {
    const {auth} = props
    const {
        total,
        users,
        isLoading, deleteUser,
        onChangePerPage, perPage,
        pageinate, page,
        onSearch, search,
        openForm,
        editUserFnc,
        updateLeader
    } = useContext(UserContext)

    const userList = useDeferredValue(users);

    const handleDelete = (id) => {
        if (window.confirm(Global.deleteMessage())) {
            deleteUser(id);
        }
    }
    const handlerLeader = (id, isLeader) => {
        if (window.confirm(Global.changeLeaderMessage())) {
            updateLeader(id, isLeader === '1' ? false : true)
        }
    }

    return isLoading ? (
        <Loading className="w-25"/>
    ) : (
        <main>
            <div className="table-head p-1">
                <h3>User List</h3>
                <form className="float-end" action="#">
                    <input type="text" placeholder={Global.placeholderSearch()}
                           className="sr-input"
                           value={search}
                           onChange={e => onSearch(e)}/>
                    <button type="button" className="btn sr-btn" onClick={onSearch}>
                        <i className="bi bi-search"></i>
                    </button>
                </form>
            </div>

            <PaginationPerPage
                perPage={perPage}
                total={total}
                pageinate={pageinate}
                currentPage={page}
                onChangePerPage={onChangePerPage}/>

            <table className="table table-striped data-table table-hover table-body p-2">
                <thead className="table-success">
                <tr>
                    <th scope="col" className="hidden-mobile">#</th>
                    <th scope="col" className="hidden-mobile">ID</th>
                    <th scope="col" className="hidden-mobile">Name</th>
                    <th scope="col" className="hidden-mobile">Email</th>
                    <th scope="col" className="hidden-mobile">Role</th>
                    <th scope="col" className="hidden-mobile">Is Leader</th>
                    {/* <th scope="col" className="hidden-mobile">Timezone</th> */}
                    <th scope="col" className="text-end hidden-mobile" colSpan={2}>
                        <a className="download btn btn-primary"
                           href={process.env.REACT_APP_API_ENDPOINT + "/export-user?token=" + auth.authorisation.token}
                           download="ss" target="_blank" rel="noreferrer">
                            <i className="bi bi-download"></i> Excel
                        </a>
                        {" "}
                        <button className="btn btn-success" onClick={openForm}><i className="bi bi-plus-lg"></i>
                        </button>
                    </th>
                    <th scope="col" className="show-mobile hidden-tablet">Users Information</th>
                    <th scope="col" className="text-end show-mobile hidden-tablet">
                        <a className="download btn btn-primary"
                           href={process.env.REACT_APP_API_ENDPOINT + "/export-user?token=" + auth.authorisation.token}
                           download="ss" target="_blank" rel="noreferrer">
                            <i className="bi bi-download"></i> Excel
                        </a>
                        {" "}
                        <button className="btn btn-success" onClick={openForm}><i className="bi bi-plus-lg"></i>
                        </button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {userList && userList.length ? (
                    userList.map((user, index) => (
                        <tr key={user.id}>
                            <th scope="row" className="hidden-mobile">{index + 1}</th>
                            <td className="hidden-mobile">{user.id}</td>
                            <td className="hidden-mobile">{user.name}</td>
                            <td className="hidden-mobile">{user.email}</td>
                            <td className="hidden-mobile">{Global.userRole(user.type)}</td>
                            <td className="hidden-mobile">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                           type="checkbox"
                                           id="flexSwitchCheckDefault"
                                           name="status"
                                           checked={Global.isLeader(user.is_leader) ? true : false}
                                           onChange={() => handlerLeader(user.id, user.is_leader)}
                                    />
                                    {/* <label className="form-check-label" htmlFor="is_leader">Is Leader</label> */}
                                </div>
                            </td>
                            {/* <td className="hidden-mobile">{user.timezone}</td> */}
                            <th scope="row" className="show-mobile hidden-tablet">
                                <p><b>#</b>{index + 1}. <b className="text-success">ID: </b>{user.id}</p>
                                <p className="text-primary"><i className="bi bi-person">{" "}</i>{user.name}</p>
                                <p><b><i className="bi bi-envelope">{" "}</i> </b>{user.email}</p>
                                <p className="btn btn-dark"><i
                                    className="bi bi-incognito">{" "}</i> {Global.userRole(user.type)}</p>
                                {/* {" "}<p className="btn btn-info"><i className="bi bi-globe2">{" "}</i>{user.timezone}</p> */}
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                           type="checkbox"
                                           id="is_leader"
                                           name="status"
                                           checked={Global.isLeader(user.is_leader) ? true : false}
                                           onChange={() => handlerLeader(user.id, user.is_leader)}
                                    />
                                    <label className="form-check-label" htmlFor="is_leader">Is Leader</label>
                                </div>
                            </th>
                            <td className="text-end">
                                <button type="button" className="btn btn-warning mt-1"
                                        onClick={() => editUserFnc(user)}>
                                    <i className="bi bi-pencil-square"></i>
                                </button>
                                {" "}
                                <div className="show-mobile hidden-tablet"><br/></div>
                                <button type="button" className="btn btn-danger mt-1"
                                        onClick={() => handleDelete(user.id)}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="hidden-mobile" colSpan={7}>There are no users.</td>
                        <td className="show-mobile hidden-tablet" colSpan={2}>There are no users.</td>
                    </tr>
                )}
                </tbody>
            </table>
            <User/>
        </main>
    )
}

export default Users
