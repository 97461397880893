import {useContext} from 'react';
import PaginationPerPage from '../../layouts/PaginationPerPage';
import {useDeferredValue} from 'react';
import Loading from '../../layouts/Loading';
import ProjectLogContext from '../../../context/ProjectLogContext';

const ProjectLog = (props) => {
    const {auth} = props

    const {
        total,
        data,
        isLoading,
        onChangePerPage, perPage,
        pageinate, page,
        onSearch, search,
    } = useContext(ProjectLogContext)

    const projectlogList = useDeferredValue(data);

    return isLoading ? (
        <Loading className="w-25"/>
    ) : (
        <main>
            <div className="table-head p-1">
                <h3>
                    <div className="hidden-mobile">Project Log Manager</div>
                    <div className="show-mobile hidden-tablet">Logs</div>
                </h3>
                <form className="float-end" action="#">
                    <input type="text" placeholder="Search ..."
                           className="sr-input"
                           value={search}
                           onChange={e => onSearch(e)}
                    />
                    <button type="button" className="btn sr-btn" onClick={onSearch}>
                        <i className="bi bi-search"></i>
                    </button>
                </form>
            </div>
            <PaginationPerPage
                perPage={perPage}
                total={total}
                pageinate={pageinate}
                currentPage={page}
                onChangePerPage={onChangePerPage}
            />
            <table className="table table-striped data-table table-hover p-2">
                <thead className="table-success">
                <tr>
                    <th scope="col" className="hidden-mobile">#</th>
                    <th scope="col" className="hidden-mobile">Project Id</th>
                    <th scope="col" className="hidden-mobile">Date</th>
                    <th scope="col" className="hidden-mobile">Action</th>
                    <th scope="col" className="hidden-mobile w-50">Content</th>
                    <th scope="col" className="hidden-mobile text-end ">
                        <a className="download btn btn-primary"
                           href={process.env.REACT_APP_API_ENDPOINT + "/export-project-log?token=" + auth.authorisation.token}
                           download="ss" target="_blank" rel="noreferrer">
                            <i className="bi bi-download"></i> Excel
                        </a>
                    </th>
                    <th scope="col" className="show-mobile hidden-tablet">
                        Project Log List
                    </th>
                    <th scope="col" className="show-mobile hidden-tablet text-end">
                        <a className="download btn btn-primary"
                           href={process.env.REACT_APP_API_ENDPOINT + "/export-project-log?token=" + auth.authorisation.token}
                           download="ss" target="_blank" rel="noreferrer">
                            <i className="bi bi-download"></i> Excel
                        </a>
                    </th>
                </tr>
                </thead>
                <tbody>
                {projectlogList && projectlogList.length ? (
                    projectlogList.map((projectlog, index) => (
                        <tr key={index}>
                            <th scope="row" className="hidden-mobile">{index + 1}</th>
                            <td className="hidden-mobile">{projectlog.project_id}</td>
                            <td className="hidden-mobile">{projectlog.date}</td>
                            <td className="hidden-mobile">{projectlog.action}</td>
                            <td className="hidden-mobile w-50" colSpan={2}>
                                <div className="body" dangerouslySetInnerHTML={{__html: projectlog.content}}></div>

                            </td>
                            <td className="show-mobile hidden-tablet" colSpan={2}>
                                <p><b>#</b>{index + 1}</p>
                                <p className="text-primary">{projectlog.project_id} : {projectlog.date}</p>
                                <div className="body" dangerouslySetInnerHTML={{__html: projectlog.content}}></div>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={6} className="hidden-mobile">There are no data.</td>
                        <td className="show-mobile hidden-tablet">There are no data.</td>
                    </tr>
                )}
                </tbody>
            </table>

        </main>
    )
}

export default ProjectLog
