import React, {useState, useEffect, useMemo, useRef} from "react";
import {useNavigate} from "react-router-dom";
import ProjectContext from "./ProjectContext";
import ProjectService from "../libs/service/ProjectService";
import moment from "moment-timezone";
import {allTimezones} from "react-timezone-select";

export const ProjectProvider = ({children}) => {
    const navigate = useNavigate()
    // const [authMail, setAuthMail] = useState([]);
    const [projects, setProjects] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalType, setTotalType] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [projectEdit, setProjectEdit] = useState({project: {}, isEdit: false});
    const [projectAssign, setProjectAssign] = useState({project: {}, isEdit: false});
    const [projectAssignClient, setProjectAssignClient] = useState({project: {}, isEdit: false});


    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenLeader, setIsOpenLeader] = useState(false)
    const [isOpenClient, setIsOpenClient] = useState(false)

    const [projectTypes, setProjectTypes] = useState([]);

    let btnRef = useRef();  //prevent-multiple-times-button-press
    let btnRefClient = useRef();  //prevent-multiple-times-button-press

    const [totalHours, setTotalHours] = useState(0);
    let refTotalHours = useRef()

    //timezone
    const [tz, setTz] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [datetime, setDatetime] = useState(moment());

    useMemo(() => {
        const tzValue = tz.value ?? tz; //??
        setDatetime(datetime.tz(tzValue));
    }, [tz, datetime]);

    const [params, setParams] = useState({
        tz: tz
    })

    const closeModal = () => {
        setIsOpen(false)
        setProjectEdit({project: {}, isEdit: false});
    }
    const openForm = () => {
        setProjectEdit({project: {}, isEdit: false});
        setIsOpen(true)
    }

    //pass item info to form edit
    const editProjectFnc = (project) => {
        setProjectEdit({project, isEdit: true});
        setIsOpen(true)
    };

    //assignee leader
    const closeModalLeader = () => {
        setIsOpenLeader(false)
    }
    const assignLeader = (project) => {
        setProjectAssign({project, isEdit: false});
        setIsOpenLeader(true)
    }

    const reAssignLeader = (project) => {
        setProjectAssign({project, isEdit: true});
        setIsOpenLeader(true)
    }

    //assignee client
    const closeModalClient = () => {
        setIsOpenClient(false)
    }
    const assignClient = (project) => {
        setProjectAssignClient({project, isEdit: false});
        setIsOpenClient(true)
    }

    const reAssignClient = (project) => {
        setProjectAssignClient({project, isEdit: true});
        setIsOpenClient(true)
    }

    /***Paging ACTION***/
    const pageinate = (pageNumber) => {
        pageNumber = pageNumber ? pageNumber : 1
        setPage(pageNumber)
        const newParams = params
        newParams.page = pageNumber
        newParams.perPage = perPage
        newParams.search = search
        newParams.tz = tz
        setParams(newParams)
    }

    // on change per page
    const onChangePerPage = (e) => {
        const newParams = params
        newParams.perPage = parseInt(e.target.value)
        newParams.page = 1
        newParams.tz = tz
        setPerPage(parseInt(e.target.value))
    }

    const onSearch = (e) => {
        e.preventDefault()
        const newParams = params
        newParams.search = e.target.value
        newParams.page = 1
        newParams.perPage = perPage
        newParams.tz = tz
        setSearch(e.target.value)
        setParams(newParams)
    }

    //call API
    const fetchProjects = () => {
        const res = ProjectService.getProjectList(params);
        res.then(response => {
            if (response.status === 401) {
                localStorage.clear()
                navigate('/')
                navigate(0)
            }
            setProjects(response.data.data)
            setTotal(response.data.total)
            setIsLoading(false)
        }).catch(error => {
            if (error.response.status === 401) { //Unauthenticated.
                localStorage.clear()
                navigate('/')
                navigate(0)
            }
            setIsLoading(false)
        })

        return projects;
    };

    const reloadProjects = () => {
        const newParams = params
        newParams.search = search
        newParams.page = page
        newParams.perPage = perPage
        newParams.tz = tz
        setParams(newParams)
        fetchProjects()
        setErrorMessage('')
    }

    // project form
    const addProject = (newProject) => {
        setIsLoading(true)
        const res = ProjectService.createProject(newProject)
        res.then((response) => {
            reloadProjects()
            setIsLoading(false)
            setIsOpen(false) //close popup
            setProjectEdit({project: {}, isEdit: false});
            if (btnRef.current) {
                btnRef.current.removeAttribute("disabled")
            }
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
        })
    };

    const updateProject = (id, project) => {
        setIsLoading(true)
        const res = ProjectService.updateProject(id, project)
        res.then((response) => {
            reloadProjects()
            setIsLoading(false)
            setIsOpen(false) //close popup
            setProjectEdit({project: {}, isEdit: false});
            if (btnRef.current) {
                btnRef.current.removeAttribute("disabled")
            }
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
        })
    };

    const deleteProject = (id) => {
        setIsLoading(true)
        const res = ProjectService.deleteProject(id)
        res.then(response => {
            setIsLoading(false)
            reloadProjects()
        }).catch(error => {
            setIsLoading(false)
        })
    };

    //load more data: call API
    const loadProjectTypes = () => {
        const res = ProjectService.getProjectTypeList();
        res.then(response => {
            if (response.status === 401) {
                localStorage.clear()
                navigate('/')
                navigate(0)
            }
            setTotalType(response.data.total)
            setProjectTypes(response.data)
            setIsLoading(false)
        }).catch(error => {
            if (error.response.status === 401) { //Unauthenticated.
                setTimeout(() => {
                    setErrorMessage(error.response.data.message)
                    localStorage.clear()
                    navigate('/')
                    navigate(0)
                }, 200);
            }
            setIsLoading(false)
        })

        return projectTypes;
    };

    const saveProjectUsers = (projectUsers) => {
        setIsLoading(true)
        const res = ProjectService.createProjectUsers(projectUsers)
        res.then((response) => {
            reloadProjects()
            setIsLoading(false)
            setIsOpenLeader(false)
            if (btnRef.current) {
                btnRef.current.removeAttribute("disabled")
            }
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
        })
    }

    const saveProjectClients = (projectClients) => {
        setIsLoading(true)
        const res = ProjectService.createProjectClients(projectClients)
        res.then((response) => {
            reloadProjects()
            setIsLoading(false)
            setIsOpenClient(false)
            if (btnRefClient.current) {
                btnRefClient.current.removeAttribute("disabled")
            }
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
        })
    }

    const calTotalHours = (inputParam) => {
        setIsLoading(true)
        const res = ProjectService.totalHoursProject(inputParam)
        res.then((response) => {
            refTotalHours.current = response.data.totalHours
            setTotalHours(response.data.totalHours)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        //live check mail
        const handleCheckProject = ({detail}) => {
            fetchProjects()

        }
        window.addEventListener(`loadProject-${params.page ? params.page : 1}`, handleCheckProject)

        fetchProjects()

        //load Project Types to selected box
        loadProjectTypes()

        return () => {
            fetchProjects()
            loadProjectTypes()
            window.addEventListener(`loadProject-${params.page ? params.page : 1}`, handleCheckProject)
        };
    }, [perPage, page, search])

    //ke ra ai thich sai cai nao thi sai
    const shareProvider = {
        total,
        projects,
        isLoading,
        addProject,
        updateProject,
        deleteProject,
        editProjectFnc,

        errorMessage,

        page,
        pageinate,
        perPage,
        onChangePerPage,
        search,
        onSearch,

        fetchProjects,

        isOpen,
        closeModal,
        openForm,
        projectEdit,

        tz,
        setTz,
        datetime,
        allTimezones,

        projectTypes,
        totalType,

        btnRef,
        projectAssign,
        assignLeader,
        reAssignLeader,
        closeModalLeader,
        isOpenLeader,
        saveProjectUsers,

        btnRefClient,
        projectAssignClient,
        assignClient,
        reAssignClient,
        closeModalClient,
        isOpenClient,
        saveProjectClients,
        // exportExcel,

        setIsLoading,

        calTotalHours,
        totalHours,
        setTotalHours
    }

    return (
        <ProjectContext.Provider value={shareProvider}>
            {children}
        </ProjectContext.Provider>
    );
};
