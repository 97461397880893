import clientInstance from "./clientInstance"

const ProjectService = {
    getProjectList: async (params) => {
        // console.log(JSON.stringify(params))
        return await clientInstance.get(
            '/project',
            {
                params: params,
            }
        )
    },

    getProjectTypeList: async () => {
        return await clientInstance.get(
            '/project/create'
        )
    },

    getProject: async (id) => {
        return await clientInstance.get(
            '/project/' + id
        )
    },

    createProject: async (params) => {
        // console.log(params)
        return await clientInstance.post(
            '/project',
            params
        )
    },

    updateProject: async (id, params) => {
        // console.log(params)
        return await clientInstance.put(
            '/project/' + id,
            params
        )
    },

    deleteProject: async (id) => {
        return await clientInstance.delete(
            '/project/' + id
        )
    },

    createProjectUsers: async (params) => {
        // console.log(params)
        return await clientInstance.post(
            '/project-user',
            params
        )
    },

    updateProjectUsers: async (id, params) => {
        // console.log(params)
        return await clientInstance.put(
            '/project-user' + id,
            params
        )
    },

    createProjectClients: async (params) => {
        // console.log(params)
        return await clientInstance.post(
            '/project-client',
            params
        )
    },

    updateProjectClients: async (id, params) => {
        // console.log(params)
        return await clientInstance.put(
            '/project-client' + id,
            params
        )
    },

    exportProject: async (params) => {
        // console.log(params)
        return await clientInstance.post(
            '/export-project',
            params
        )
    },

    totalHoursProject: async (params) => {
        // console.log(params)
        return await clientInstance.get(
            '/total-hours',
            {
                params: params,
            }
        )
    },

    pushNotifications: async (params) => {
        return await clientInstance.get(
            '/push-notification',
            {
                params: params,
            }
        )
    },
}

export default ProjectService
